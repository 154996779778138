.c-address-container {
    --bg-opacity: .1;//get-config-value(10, 'opacity');
    background-color: rgba(get-color('primary'), var(--bg-opacity));

    @include media-query('lg') {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
    }
}

.c-address {
  padding: var(--step-2);//get-size('075') get-size('1');
  
  .c-ap > div {
    flex-basis: var(--step-7);
  }

  @include media-query('md-s') {
    .c-address__description {
      display: flex;
      flex-wrap: nowrap;
    }
    
    .c-address__contact {
      flex: 0 0 50%;
      padding-right: var(--step-2);      
    }      
    
    .c-address__postal {
      margin-top: 0;
    }
  }
  
  @include media-query('md-x') {
    display: flex;
    flex-wrap: nowrap;
    
    .c-address__description {
      display: block;
    }
    
    .c-address__ap {
      flex: 0 0 60%;
      padding-right: var(--step-2);      
    }
    
    .c-address__postal {
      margin-top: var(--step-2);
    }
  }
  
  @include media-query('lg') {
    align-self: flex-end;
    display: block;
    flex: 0 0 50%;    

    .c-address__postal {
      margin-top: var(--step-0);
    }
  }
}

/**
@include media-query('md-x') {
  .c-address__description {
    display: flex;
    flex-wrap: nowrap;
  }
  
  .c-address__description > p {
	flex: 0 0 60%;
	padding-right: var(--step-2);
  }
  
  .c-address__data {
	margin-top: 0;
  }
}
*/
