.slick-track {
  display: flex;
  align-items: center;
  justify-content: stretch;
}

/* Arrows 
----------------------------------------------------------------------------- */
.slick-prev,
.slick-next, 
.slick-prev--custom, 
.slick-next--custom {
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: absolute;
  height: 2rem;
  width: 2rem;
  top: 50%;
  transform: translate(0, -50%);
  
  background-color: transparent;
  //background-color: get-color('secondary');
  //border-radius: 100%;
  cursor: pointer;
  font-size: var(--step-3);
  outline: none;
  opacity: $slick-opacity-default;
  
  &.slick-disabled {
    opacity: $slick-opacity-not-active;
  }
}

.slick-prev,
.slick-next {  
  font-size: 0px;
  line-height: 0px;
  
  &:hover, 
  &:focus {
    outline: none;
    opacity: $slick-opacity-on-hover;
  }
  
  &:before {
    font-size: get-size('1');
    line-height: 1;
    color: $slick-arrow-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-next, 
.slick-next--custom {
  right: -2.5rem;
}

.slick-next {
  &:before {
    content: $slick-next-character;
  }  
}

.slick-prev,
.slick-prev--custom {
  left: -2.5rem;
}

.slick-prev {
  &:before {
    content: $slick-prev-character;
  }
}

/* Dots
----------------------------------------------------------------------------- */
/*.slick-dotted {
  &.slick-slider {
    margin-bottom: get-size('2');
  }
}*/

.slick-dots {
  position: absolute;
  bottom: calc(var(--step-2) * -1);
  width: 100%;
  display: flex;
  justify-content: center;
  
  .c-jobs-slider & {
    bottom: calc(var(--step-4) * -1);
  }
  
  li {
    margin: 0 get-size('05');
    
    button {
      background-color: rgba($slick-dot-color, $slick-opacity-not-active);
      border-radius: 100%;
      font-size: 0;
      height: 1rem;//get-size('0');
      width: 1rem;//get-size('0');
      
      &:hover, &:focus {
          outline: none;
          background-color: rgba($slick-dot-color, $slick-opacity-on-hover);
      }
    }
    
    &.slick-active button {
        background-color: rgba($slick-dot-color-active, $slick-opacity-default);
    }
  }
}
