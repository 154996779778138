.u-icon {
  width: 1.25em;
  //width: 1cap;
  height: 1.25em;
  //height: 1cap;
  
  &.is-solid {
    .u-icon__path {
      a &, 
      .color-primary & {
        fill: get-color('primary');
      }
      .color-white & {
        fill: get-color('white');
      }
    }
  }
  
  &.is-outline {
    .u-icon__path {
      a &, 
      .color-primary & {
        stroke: get-color('primary');
      }
      .color-white & {
        stroke: get-color('white');
      }
    }    
  }  
  
  .color-white & {
    .path {
      fill: get-color('white');
    }
  }
  
  /**&[dir="rtl"] {
    .u-icon {
      margin-left: 0;
      margin-right: get-size('-4');
    }
  }*/
}

.u-with-icon {
  display: inline-flex;
  align-items: center;
  column-gap: get-size('-4');
}

// Only Safari 10.1+
// https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
    .u-with-icon {
      .u-icon {
        margin-left: get-size('05');
      }
      
      &[dir="rtl"] {
        .u-icon {
          margin-left: 0;
          margin-right: get-size('-4');
        }
      }
    }
}}
