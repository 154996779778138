.c-cards {  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;//space-between;
  flex-wrap: wrap;  
  
  //@include media-query('md-x') {
    //flex-wrap: nowrap;
  //}
}

.c-card {
  border: 1px solid get-color('grey-500');
  box-shadow: 0 0 0.5rem get-color('white');
  transition: .25s;
  
  img {
    transition: .5s;
  }
  
  &:hover,
  &:focus {
    border: 0 solid get-color('white');
    box-shadow: 0 0 0.5rem get-color('grey-500');
    
    img {
      transform: scale(1.1);
    }
  }
  
  flex-shrink: 1;
  flex-grow: 0;
  flex-basis: 100%;
  
  margin-bottom: var(--step-1);
  margin-left: 0;
  
  @include media-query('sm') {
    margin-left: var(--step-1);
    flex-basis: calc(50% - var(--step-1))
  }
  
  @include media-query('md-x') {
    flex-basis: calc(33% - var(--step-1))
  }  
}


