body {
  overflow-x: hidden;
  
  //&.is-pg-service {
  //  padding-top: var(--marginTop);
  //}
}

// Set Image and Objects to have a max-width of 100%
embed,
iframe,
object,
video {
  max-width: 100%;
}

button {
  cursor: pointer;
}
