.u-auto-grid {
  display: -ms-grid;
  display: grid;
  gap: var(--step-0, 1rem);
}

@supports (width: min(15.8rem, 100%)) {
  .u-auto-grid {
    grid-template-columns: repeat(auto-fit, minmax(min(15.8rem, 100%), 1fr));
  }
}

.u-auto-grid {
  &--li {
      > li {
        padding-bottom: var(--grid-img-ratio);
      }    
  }
  
  &--li-a {
      > li > a {
        padding-bottom: var(--grid-img-ratio);
      }    
  }
}