.c-location__frame {
  --frame-n: 16;
  
  @include media-query('sm') {
    --frame-n: 10;
  }
  
  @include media-query('md') {
    --frame-n: 16;
  }
}

.c-location__label {
  --bg-opacity: 0.75;
  background-color: rgba(get-color('primary'), var(--bg-opacity));
  bottom: 0;
  color: get-color('white');
  font-family: var(var(--font-primary-bold));
  font-size: var(--step-2);
  padding: var(--step-0);
  position: absolute;
  width: 100%;
}
