.u-stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.u-stack > * {
  margin-top: 0;
  margin-bottom: 0;
} 

.u-stack > button {
  align-self: flex-start;
}

.u-stack {
  & > * {
    & + * {
      margin-top: get-size('4');
    }
    & + a {
       margin-top: 0;
    }
    & + .mt--4 {
      margin-top: get-size('-4');
    }
    & + .mt--3 {
      margin-top: get-size('-3');
    }
    & + .mt--2 {
      margin-top: get-size('-2');
    }
    & + .mt--1 {
      margin-top: get-size('-1');
    }
    & + .mt-0 {
      margin-top: get-size('0');
    }
    & + .mt-1 {
      margin-top: get-size('1');
    }
    & + .mt-2 {
      margin-top: get-size('2');
    }
    & + .mt-3 {
      margin-top: get-size('3');
    }
  }
}

.u-stack-0 > * + * {
  margin-top: get-size('0');
}

.u-stack-05 > * + * {
  margin-top: get-size('05');
}

.u-stack-025 > * + * {
  margin-top: get-size('025');
}

.u-stack-1 > * + * {
  margin-top: get-size('1');
}

.u-stack-2 > * + * {
  margin-top: get-size('2');
}

.u-stack-4 {
  & > * {
    & + * {
      margin-top: get-size('4');
    }
    & + a {
       margin-top: 0;
    }    
  }  
}

.u-stack-6 {
  & > * {
    & + * {
      margin-top: get-size('6');
    }
    & + a {
       margin-top: 0;
    }    
  }  
}

.u-stack-8 > * + * {
  margin-top: get-size('8');
}


@include media-query('lg') {
  .is-content--service {
    .u-stack > * + * {
      margin-top: get-size('6');
    }
  }
}