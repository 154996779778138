.c-nav {
  position: relative;
  z-index: 1;

  @include media-query('lg') {
	flex: 0 0 auto;
    position: relative;
    z-index: auto;
  }
}

.c-nav-main-toggle {
  background-color: transparent;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  
  .lines-button.x2 {
    .lines {
      transition-delay: 0s;
    }
  }

  .c-nav-main-toggle__label {
    color: get-color('white');
  }
  
  &.is-positive[aria-expanded="false"], 
  &.is-positive-gsap[aria-expanded="false"] {
    .lines, 
    .lines::after,
    .lines::before {
      background-color: get-color('black');
    }

    .c-nav-main-toggle__label {
      color: get-color('black');
    }
  }

  @include media-query('lg') {
    display: none;
  }
}
    
.c-nav-main-wrapper {
  transition: .5s;
  position: fixed;
  background-color: rgba(get-color('black'), .8);
  height: 100vH;
  overflow: scroll;
  padding: calc(var(--step-2) * 4) var(--step-2) var(--step-2) var(--step-2);
  right: -100vw;
  top: 0;
  width: 100vw;

  @include media-query('md') {
    width: 66vw;
  }
  
  @include media-query('lg') {
    background-color: transparent;
    overflow: visible;//hidden;
    position: relative;//static;
    height: auto;
    right: auto;
    width: auto;
    padding: 0;
    z-index: auto;
  }  
}

.c-nav-main-toggle[aria-expanded="true"] ~ .c-nav-main-wrapper {
  right: 0;
}   


.c-nav-main {
  overflow: visible !important;
  padding-bottom: var(--step-2);
  
  a {
    text-decoration: none;
  }
  
  @include media-query('lg') {
    --space: 1px;  
    padding-bottom: 0;
    position: relative;//absolute;
    right: 0;
    top: calc(100% - var(--step-2));
    
    & > ul {
      display: flex;
    }
  }
}

// level 1
// -----------------------------------------------------------------------------
.c-nav-main > ul > * {
  padding-top: var(--step-1);
}
.menu-item--level1 { 
  & > a {
    color: get-color('secondary');
  }
}

// level 2
// -----------------------------------------------------------------------------
.menu-item--level1 > a ~ ul > * {
  margin-top: 0.5rem;
}
  
.menu-item--level2 {
  border: 1px solid get-color('grey-300');
    
  .menu-position-wrapper--level2 {    
    .menu-link--level2 {
      color: get-color('white');
      display: block;
      padding: var(--step--2);

      & > div > img {
        display: none;
      }

      & ~ ul {
        display: none;
      }

      &[aria-expanded="true"] ~ ul {
        display: block;
      }
          
      & > span {          
        .u-icon {
          display: none;
        }
      }
      
      &.is-active {
        @include apply-utility('font', 'primary-bold');
        color: get-color('black');
        background-color: get-color('secondary');
      }
    }
  }

  &.is-open {
    background-color: rgba(get-color('primary'), .5);

    .menu-link--level2 {
      @include apply-utility('font', 'primary-bold');
      background-color: get-color('primary');
    }
  }
  
  &.has-submenu {
    .menu-link--level2 {
      & > span {
        &:after {
          content: " ▾"
        }
      }
    }
  }
}

// level 3
// -----------------------------------------------------------------------------
.menu-item--level3 {
  border-top: 1px solid get-color('grey-300');
}

.menu-item--level3__title, 
.menu-item--level3__link {
  color: get-color('white');
  display: block;
  padding: var(--step--2);

  & ~ ul {
    padding-left: var(--step-4);
  }
}

.menu-item--level3.has-submenu .menu-item--level3__link, 
.menu-item--level3__title {
  line-height: 1;
  padding-bottom: 0;

  &:after {
    content: " ▾"
  }
}

// level 4
// -------
//.c-nav-main > ul > li > ul > li > ul > li > ul > li 
.menu-item--level4__link {
  color: get-color('white');
  display: inline-block;
  padding: calc(var(--step--2) * .5);
}


// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// lg
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

@include media-query('lg') {
  // level 1
  // ---------------------------------------------------------------------------
  .c-nav-main > ul > * {
    padding-top: 0;
  }
  
  .menu-item--level1 {
    .menu-overlay {
        background: rgba(0,0,0,0.3);
        //display: none;
        height: 100%;
        left: 100%;
        margin-left: -100vw;
        opacity: 0;
        position: absolute;
        width: 100vw;
    }
    
    &.is-open {
      .menu-overlay {
        opacity: 1;        
      }
    }
      
    & > a { 
      background-color: get-color('primary');
      color: get-color('white');
      display: inline-block;
      font-size: get-size('-1');
      line-height: get-size('2');  
      padding: 0 get-size('0');
    }

    & > a ~ ul {
      background-color: rgba(darken(get-color('primary'), 20%), .9);
      display: none;
      left: 100%;
      margin-left: -100vw;
      position: absolute;
      width: 100vw;
    }

    & > a[aria-expanded="true"] ~ ul {
      align-items: stretch;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: get-size('1');
      gap: get-size('1');
    }
    
    &.has-submenu > a { 
      &:after {
        content: " ▾"
      }
    }

    &.is-open > a, 
    &.is-active > a {
      background-color: get-color('secondary');
      color: get-color('black');
    }
  }

  // level 2
  // ---------------------------------------------------------------------------
  .menu-item--level1 > a ~ ul > * {
    margin-top: 0;
  }
  
  .menu-item--level2 {
    border: 0 none;
    flex: 1;
    position: relative;
    border-radius: 4px;
    
    #nav_ & {
      flex: 1 0 20%;
    }
    
    &.is-vhp,
    &.is-bsw,
    &.is-bgz {
      .menu-position-wrapper--level2 {
        flex-flow: row-reverse;
      }
    }
    
    &.is-hg {
      .menu-position-wrapper--level2 {
        .menu-link--level2 {
          & ~ ul {
            flex-basis: 250%;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: stretch;//flex-start;
          }
        }
      }      
    }
    
    &.is-tp {
      .menu-position-wrapper--level2 {
        .menu-link--level2 {
          & ~ ul {
            flex-basis: 150%;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            //align-items: stretch;
          }
        }
      }      
    }
    
    .menu-position-wrapper--level2 {
      display: flex;
      height: 100%;
      
      .menu-link--level2 {
        --step-4: 0;
        background-color: get-color('primary');
        border-radius: .25rem;
        color: get-color('white');
        display: block;
        font-size: var(--step--1);
        line-height: 1.25;
        overflow: hidden;
        padding: 0;//var(--step--3) var(--step-0);
        position: relative;
        text-decoration: none;
        flex: 1 0 100%;
        z-index: 2;
        
        &.has-no-img {
          font-size: var(--step-0);
        }
        
        & ~ ul {
          background-color: get-color('white');
          flex: 1 0 100%;
          flex-direction: column;
          justify-content: space-between;
          padding: 0;
          position: relative;
        }
        
        .lg\:u-frame {
          --frame-n: 9;
        }

        & > div > img {
          display: block;
        }
        
        span {
          display: inline-flex;
          //flex-direction: row-reverse;
          padding: get-size('-2');
          
          .u-icon {
            display: block;
            flex-shrink: 0;
          }
          
          &.u-with-icon {
            padding-left: var(--step--4);
            .minus {
              display: none;
            }
          }
        }
        
        &.has-no-img {
          font-size: var(--step-0);
          
          span {
            padding: get-size('0');
          }
        }
      }
    }

    &.is-open {
      --border-radius: .25rem;
      --shadow-x: .25rem;
      --shadow-y: .25rem;
      --shadow-width: .5rem;
      --shadow-opacity: .5;
      
      background-color: transparent;
      z-index: 100;
      
      @keyframes width_growing {
        from {
          transform: translateX(0) translateY(0);
          width: 100%;
        }
        to {
          transform: translateX(-2.5%) translateY(-2.5%);
          width: 110%;
        }
      }
      
      @keyframes width_growing_TwoColsFirstTwo {
        from {
          width: 100%;
          transform: translateX(0) translateY(0);
        }
        to {
          transform: translateX(-1.5%) translateY(-1.5%);
          width: 200%;
        }
      }
      
      @keyframes width_growing_ThreeColsFirstTwo {
        from {
          width: 100%;
          transform: translateX(0) translateY(0);
        }
        to {
          transform: translateX(-1.5%) translateY(-1.5%);
          width: 300%;
        }
      }
      
      @keyframes width_growing_TwoColsLastTwo {
        from {
          width: 100%;
          transform: translateX(0) translateY(0);
        }
        to {
          transform: translateX(-6.5%) translateY(-2.5%);
          width: 110%;
        }
      }
      
      /**@keyframes width_growing_lastTwo {
        from {
          width: 100%;
          transform: translateX(0) translateY(0);
        }
        to {
          transform: translateX(-12.5%) translateY(-2.5%);
          width: 110%;
        }
      }*/
      
      .menu-position-wrapper--level2 {
        animation: .25s ease-in-out alternate width_growing;
        box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-width) rgba(0,0,0,var(--shadow-opacity));
        position: absolute;
        height: auto;
        transform: translateX(-2.5%) translateY(-2.5%);
        width: 110%;
        
        .menu-link--level2 {
          background-color: get-color('secondary');
          border-radius: var(--border-radius) 0 0 var(--border-radius);
          color: get-color('black');
          font-size: var(--step-0);
          
          & > span {
            padding: var(--step--1) var(--step-0);
          
            &.u-with-icon {
              padding-left: var(--step--4);
              .plus {
                display: none;
              }
              .minus {
                display: block;
              }
            }
          }
          
          &[aria-expanded="true"] ~ ul {
            display: flex;
            border-radius: 0 var(--border-radius) var(--border-radius) 0;
            box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-width) rgba(0,0,0,var(--shadow-opacity));
            overflow: hidden;
          }
        }
      }
    
      /**&.is-ad {
        .menu-position-wrapper--level2 {
          animation: .25s ease-in-out width_growing_TwoColsFirstTwo;
          transform: translateX(-1.5%) translateY(-1.5%);
          width: 200%;
          
          .menu-link--level2 {
            flex-basis: 50%;
            
            &[aria-expanded="true"] ~ ul {
              flex-basis: 50%;
            }
          }
        }
      }*/
    
      /*&.is-tp {
        .menu-position-wrapper--level2 {
          animation: .25s ease-in-out width_growing_ThreeColsFirstTwo;
          transform: translateX(-1.5%) translateY(-1.5%);
          width: 300%;
          
          .menu-link--level2 {
            flex-basis: 33%;
            
            &[aria-expanded="true"] ~ ul {
              flex-basis: 66%;
            }
          }
        }
      }*/
      
      &.is-kup,
      &.is-vhp,
      &.is-bsw,
      &.is-bgz {
        --shadow-x: -.25rem;
        .menu-position-wrapper--level2 {
          animation: .25s ease-in-out width_growing_TwoColsLastTwo;
          transform: translateX(-6.5%) translateY(-2.5%);
          box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-width) rgba(0,0,0,var(--shadow-opacity));
        
          .menu-link--level2 {
              border-radius: 0 var(--border-radius) var(--border-radius) 0;
              
              &[aria-expanded="true"] ~ ul {
                border-radius: var(--border-radius) 0 0 var(--border-radius);
                box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-width) rgba(0,0,0,var(--shadow-opacity));
              }
          }
        }
      }
    
      &.is-hg {
        .menu-position-wrapper--level2 {
          @keyframes width_growing_hg {
            from {
              width: 100%;
              transform: translateX(0) translateY(0);
            }
            to {
              transform: translateX(-2.5%) translateY(-2.5%);
              width: 120%;
            }
          }
          animation: .25s ease-in-out width_growing_hg;
          width: 120%;
          
          .menu-link--level2 {
            flex-basis: 90%;
          }
        }
      }
    }
  
    &.has-submenu {
      .menu-link--level2 {
        & > span {
          &:after {
            content: ""
          }
        }
      }
    }
  }

  // level 3
  // ---------------------------------------------------------------------------
  .menu-item--level3 {
    border: 0 none;
    font-size: var(--step--1);
    flex: 1 1 33%;

    .is-tp & {
      flex: 0 0 50%;
    
      &:nth-child(odd) {
        border-right: 1px solid get-color('grey-500');
      }
      
      &:nth-child(8) {
        border-bottom: 1px solid get-color('grey-500');
      }
    }

    .is-bsw & {
      flex: 0 1 25%;      
    }
    
    .is-hg & {
      //align-self: stretch;
      display: flex;
      flex-direction: column;
    
      &:nth-child(n+1) {
        border-right: 1px solid get-color('grey-500');
      }
      
      &.has-submenu { 
        padding-bottom: var(--step--3);
      }
      
      .menu-item--level3__title {
        @include apply-utility('font', 'primary-bold');
        color: get-color('grey-700');
        flex: 0 0 1%;
        font-size: get-size('-2');
        line-height: 1;
        display: inline-block;
        padding: var(--step--2) var(--step-0) var(--step--4) var(--step-0);

        &:after {
          content: ""
        }
        
        & ~ ul {
          flex: 1 1 100%;
          align-content: flex-start;
        }
      }
    }

    .menu-item--level3__link, 
    .menu-item--level3__title {
      align-items: center;
      border: 0 none;
      //background-color: #0c0;
      //color: get-color('grey-700');
      color: get-color('primary');
      display: flex;
      height: 100%;
      width: 100%;
      padding: var(--step--3) var(--step-0);
      text-decoration: none;

      //.c-nav-main > ul > li > ul > li > ul > li > ul
      & ~ ul {
        background-color: get-color('white');
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-left: var(--step-0);
      }
    }
    
    & + * {
      border-top: 1px solid get-color('grey-500');
    }
  }

  /**.menu-item--level3.has-submenu .menu-item--level3__title {
    line-height: 1;
    padding-bottom: 0;

    &:after {
      content: ""
    }
  }*/

  // level 4
  // ---------------------------------------------------------------------------
  //.c-nav-main > ul > li > ul > li > ul > li > ul > li 
  .menu-item--level4 {
    flex: 0 0 100%;
    margin-left: 1em;
  }
    
  .menu-item--level3__link, 
  .menu-item--level4__link {
    color: get-color('primary');
    display: block;
    //padding: .125rem 0;
    padding: var(--step--2);    
    position: relative;

    &::before {
      background-color: get-color('secondary');
      border-radius: 100%;
      content: "";
      display: inline-block;
      flex: 0 0 auto;
      height: .5em;
      margin-right: 0.5em;
      position: relative;
      //top: -.125em;
      width: .5em;
    }
  }
  .menu-item--level4__link {
    &::before {
      margin-right: -.75em;//0.5em;
      left: -1em;
    }
  }
  
  .menu-item--level4__link {
    padding: .125rem 0;
    line-height: 1.05;
    margin-bottom: .45em;
    
    span {
      color: get-color('grey-700');
      font-size: var(--step--2);
    }
  }

  .c-nav-main--sub {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    background-color: #fc0;

    /*& > div {
      display: none;

      &[aria-expanded="true"] {
        display: block;      
      }
    }*/
  }

  .c-nav-sub__container {
    display: none;
  }

  .c-nav-main #main-menu-state {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  .c-nav-main .main-menu-btn {
    color: #3467cc;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding-right: 1.75em;
    position: absolute;
    right: 1.5em;
    top: 1.5em;
    white-space: nowrap;
    width: auto;
    z-index: 8;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body:not(.is-pg-frontpage) .c-nav-main .main-menu-btn {
    top: -99999px;
  }
}