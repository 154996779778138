.c-news-headline {
  line-height: get-size('4');
  
  @include media-query('sm') {
    line-height: get-size('5');
  }
}

.c-news__subline {
  @include apply-utility('font', 'secondary-bold');
  color: get-color('primary');
  font-size: get-size('1');
  line-height: 1.25;//get-size('2');
  //margin-bottom: get-size('075');
}

.c-news__aside {
  margin-top: var(--step-4);
}

.c-news__link {
  display: inline-block;
  margin-bottom: var(--step-4);
  color: get-color('grey-700');
}

.c-news__figure {
  & > * + * {
    margin-top: var(--step--2);
  }
}

.c-news__caption {
  font-size: var(--step--1);
}
  
@include media-query('md-s') {
  .c-news__aside {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: calc(var(--step-0) * -1);
  }
  
  .c-news__link {
    flex: 0 0 calc(50% - var(--step-0));
    margin-left: var(--step-0);
  }
}