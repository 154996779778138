$secondary: #89a; // "accept necessary" button color

.cookie-consent-modal {
  padding-top: 0;
  position: fixed;
  width: auto;
  height: auto;
  z-index: 1000;
  //font-family: sans-serif;

  .modal-content-wrap {
    position: fixed;
    bottom: 0;
    margin: var(--step-0);

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    }

    .modal-content {
      //border: 1px solid rgba(0, 0, 0, 0.2);
      background-color: get-color('grey-100'); // the modals background color
      //color: #123; // the modals text color
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); // the modals shadow
      padding: 0;
      // width: 95%;
      max-width: 768px;
      border-radius: 2px;

      .modal-header {
        padding-top: var(--step--1);
        padding-right: var(--step-0);
        //padding-bottom: var(--step--3);
        padding-left: var(--step-0);
        //border-bottom: 1px solid rgba(0, 0, 0, 0.1); // bootstrap-like lines

        h3 {
          font-size: var(--step--1);
          margin: 0;
          //font-size: 130%;
          //font-weight: 500;
          position: relative;
          //top: 0.2rem;
        }
      }

      .modal-body {
        font-size: var(--step--2);
        padding-right: var(--step-0);
        padding-bottom: var(--step--2);
        padding-left: var(--step-0);
        //line-height: 1.3;
        
        & > * + * {
          margin-top: var(--step--4);
        }

        a {
          color: get-color('primary');

          &:hover {
            color: darken(get-color('primary'), 10%);
          }
        }

        li {
          position: relative;
          padding-left: 1.25em;

          &::before {
            background-color: get-color('secondary');
            border-radius: 100%;
            content: "";
            display: inline-block;
            flex-shrink: 0;
            height: .5em;
            left: 0.25em;
            position: absolute;
            top: .5em;
            width: .5em;        
          }
        }
      }

      .modal-footer {
        padding-right: var(--step-0);
        padding-bottom: var(--step--2);
        padding-left: var(--step--2);
        //padding: 1rem 0.5rem 0.5rem 0.5rem;
        // border-top: 1px solid rgba(0,0,0,0.1); // bootstrap-like lines

        .buttons {
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;

          .btn {
            @extend .c-button;
            //padding: 0.7rem 1.1rem;
            //font-size: 100%;
            //cursor: pointer;
            border: none;
            font-size: var(--step--2);
            //border-radius: 0.2rem;
            margin-left: var(--step--2);
            //margin-right: var(--step--2);
            margin-bottom: var(--step--2);

            // "accept all" button
            &.btn-primary, 
            &.btn-secondary {
              background-color: get-color('secondary');
              color: get-color('black');

              &:hover {
                background-color: darken(get-color('secondary'), 10%);
              }
            }
          }
        }
      }
    }
  }

  .btn {
    width: 100%;
    @include media-query('md-s') {
      width: auto;
    }
  }
  
  &.block-access {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5); // the fullscreen background layer
    padding-top: 20vh; // modals vertical position in desktop mode
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    @media screen and (max-width: 620px) {
      padding-top: 0;
    }

    .modal-content-wrap {
      position: relative;
      margin: 2.5% auto;

      .modal-content {
        border: none;
        margin: 0 auto;
      }

      bottom: auto;
      width: 95%;
    }
  }
}
