.c-sidebar-nav {
  display: none;
  position: absolute;
  width: var(--sidebarWidth);
  z-index: 10;
  
  @include media-query('lg') {
    left: calc(var(--sidebarWidth) * -1);
  }
  
  & > a {
    @include circle(get-size('0'));
    @include u-bg-color('grey-500');
    display: block;
    margin-left: auto;
    margin-right: auto;
    
    &:hover {
      @include u-bg-color('primary');      
    }
    
    &:focus {
      @include u-bg-color('secondary');      
    }
  }
  
  & > a.is-active {
    @include u-bg-color('primary');      
  }
  
  & > * + * {
    margin-top: get-size('1');
  }
}
