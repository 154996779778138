.u-content-wrapper {
  &.is-ma-grid {
    max-width: 1440px;
  }
}

.c-media-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
  &.c-media-grid--ma {
    justify-content: flex-start;
    margin-left: -1rem;
  }
}

h3 + .c-media-grid {
  margin-top: var(--step--2);
}
  
.c-mediablock {
  border-radius: 12px;
  border: 2px solid rgba(52, 103, 204, 0.2);
  
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  align-items: center;
  padding: var(--step-0);
  margin-bottom: var(--step-0);
  
  @include media-query('sm') {
    flex: 0 0 calc(50% - 1rem);
  }
  
  @include media-query('md-x') {
    flex-basis: calc(33.333% - 1rem);
  }
  
  @include media-query('lg') {
    flex-basis: calc(25% - 1rem);
  }

  .c-media-grid--ma & {
    margin-left: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
}

.c-mediablock__frame {
  border-radius: 100%;
  width: 33.333%;
  overflow: hidden;
  margin-bottom: var(--step--2);
  //margin-top: var(--step--1);
  @include media-query('sm') {
    width: 75%;
  }
}

.c-mediablock__frame .u-frame {
  --frame-n: 1;
  --frame-d: 1;
}

.c-mediablock-popup__body {
  display: none;
}
