/*
.menu-button-links {
  margin: 0;
  font-size: 110%;
}

.menu-button-links button {
  margin: 0;
  padding: 6px;
  display: inline-block;
  position: relative;
  background-color: #034575;
  border: 1px solid #034575;
  font-size: 0.9em;
  color: white;
  border-radius: 5px;
}
*/
.menu-button-links button {
  border: 2px solid get-color('primary');
}

.menu-button-links [role="menu"] {
  border-radius: 0 var(--step--1) var(--step--1) var(--step--1);
}

/*
.menu-button-links [role="menu"] {
  margin: 0;
  padding: 7px 4px;
  list-style: none;
  display: none;
  position: absolute;
  border: 2px solid #034575;
  border-radius: 5px;
  background-color: #eee;
}
.menu-button-links [role="menuitem"],
.menu-button-links [role="separator"] {
  margin: 0;
  padding: 6px;
  display: block;
  width: 24em;
  background-color: #eee;
  border: none;
  color: black;
  border-radius: 5px;
}

.menu-button-links [role="separator"] {
  padding-top: 3px;
  //background-image: url("../images/separator.svg");
  background-position: center;
  background-repeat: repeat-x;
}
*/

/*
.menu-button-links button svg.down {
  padding-left: 0.125em;
  fill: currentColor;
  stroke: currentColor;
}

.menu-button-links button[aria-expanded="true"] svg.down {
  transform: rotate(180deg);
}
*/

// focus styling
.menu-button-links button:hover,
.menu-button-links button:focus,
.menu-button-links button[aria-expanded="true"] {
  background-color: get-color('secondary');
  border-color: get-color('secondary');
  color: get-color('black');
  outline: none;
  
  .path {
    fill: get-color('black');
  }
}

.menu-button-links [role="menuitem"]:focus {
  background-color: get-color('white');
  border-color: get-color('primary');
  color: get-color('primary');
  
  .path {
    fill: get-color('primary');
  }
}

.menu-button-links button[aria-expanded="true"] {
  border-radius: var(--step--1) var(--step--1) 0 0;
}
