@charset 'UTF-8';

// configs 
// ---------
@import 'config/config';

// Libraries 
// ---------
//@import 'normalize-css/normalize';
@import 'modern-css-reset/dist/reset';
@import 'gorko/gorko.scss';

// Helpers 
// -------
@import 'helpers/h-utilities';
@import 'helpers/h-mixins';
@import 'helpers/h-shapes';
@import 'helpers/h-trigonometry';

// Fonts
// -----
@import 
'fonts/Amble'
//, 'fonts/Entypo'
, 'fonts/Vollkorn'
;

// Vendors
// -------
@import 
//'@fancyapps/fancybox/dist/jquery.fancybox'
//',@fancyapps/ui/dist/fancybox.css',
'slick-carousel/slick/slick'
,'inclusive-menu-button/inclusive-menu-button.css'
;
// Reset and base files (atoms)
// ----------------------------
@import 
'config/settings'
,'base/b-base'
,'base/b-utilities'
,'base/b-typography'
,'base/b-forms'
//,'slick-carousel/slick/slick-theme'
;

// Layouts (macro, no presentational styles)
// -------
//@import 
//'layout/l-frame'
//,'layout/l-with-sidebar'
//;

// utilities
// --------------------
@import 
'utilities/u-global'
,'utilities/u-custom'
,'utilities/u-wrapper'
//,'utilities/u-content-wrapper'
,'utilities/u-stack'
,'utilities/u-auto-grid'
,'utilities/u-cluster'
,'utilities/u-icon'
,'utilities/u-frame'
,'utilities/u-switcher'
//,'utilities/u-cover'
;

// Components oder Module oder Blocks
// -------
@import 
'components/c-cookie-consent-custom'
,'components/c-loader'
,'components/c-slick-custom'
,'components/c-fancybox-custom'
//,'components/c-a11y-accordion-tabs-custom'
,'components/c-accessible-tabs'
,'components/c-site-header'
,'components/c-nav-main'
,'components/c-headline'
,'components/c-keyvisual'
,'components/c-main'
,'components/c-roof'
//,'components/c-content'
,'components/c-intro'
,'components/c-sidebar-nav'
,'components/c-footer'
,'components/c-accordion'
,'components/c-button'
,'components/c-services-front'
,'components/c-address'
,'components/c-ap'
,'components/c-map'
,'components/c-tour'
,'components/c-jobs'
,'components/c-ap-site'
,'components/c-dates'
,'components/c-interrupter'
,'components/c-media-grid'
,'components/c-links'
,'components/c-sitemap'
//,'components/c-vertical-timeline'
,'components/c-news'
,'components/c-frontpage'
,'components/c-fp-news'
//,'components/c-fp-slider'
,'components/navicon-transformicons'
,'components/c-listbox'
,'components/c-cards'
,'components/c-ct-video'
,'components/c-menu-button-links'
;
