.c-interrupter-ap {
  --bg-opacity: .1;//get-config-value(10, 'opacity');
  background-color: rgba(get-color('primary'), var(--bg-opacity));
  
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--step-1);
  
  //margin-bottom: var(--step-2);
  //margin-top: var(--step-2);
}

.c-interrupter-ap__frame {
  border-radius: 100%;
  width: 25%;
  overflow: hidden;
  margin-bottom: var(--step--1);
  margin-top: var(--step--1);
}

.c-interrupter-ap__frame .u-frame {
  --frame-n: 1;
  --frame-d: 1;
}

.is-editorial {
  blockquote {
    @include apply-utility('font', 'primary-light-italic');
    color: get-color('primary');
    font-size: var(--step-4);//2.375em;
    line-height: 1.25;
    margin-bottom: var(--step-1);
    margin-top: var(--step-1);
  }  
}