:root {
  --grid-img-n: 9;
  --grid-img-d: 16;
  --grid-img-ratio: calc(var(--grid-img-n) / var(--grid-img-d) * 100%);
  --service-n: 9;//6;
  --service-d: 16;
  --marginTop: calc(var(--service-n) / var(--service-d) * 100%);
  --roofTan: 0;//#{tangens(29)};
  --roofTopX: 67;
  --headlineHeightFactor: .75;
  --roofHeightInt: calc(var(--roofTan) * var(--roofTopX));
  --roofHeight: calc(var(--roofHeightInt) * 1%);
  --roofHeightvw: calc(var(--roofHeightInt) * 1vw);
  --headlineHeight: calc(var(--roofHeight) * var(--headlineHeightFactor));
  --headlineHeightvw: calc(var(--roofHeightvw) * var(--headlineHeightFactor));
  --keyvisualHeight: calc(var(--marginTop) + var(--roofHeight));
  --keyvisualHeightvw: calc(var(--marginTop) + var(--roofHeightvw));
  --sidebarWidth: var(--step-3);//calc((var(--roofHeight) - var(--headlineHeight)) / var(--roofTan));
  
  
  
    
  --roofFrontTopX: 33;
  --roofFrontpageTanAlpha: #{tangens(15)};
  --roofFrontpageTanBeta: #{tangens(36)};
  --roofFront_a: calc(var(--roofFrontpageTanBeta) * var(--roofFrontTopX) * 1%);
  //--roofFront_b: calc(var(--roofFrontpageTanAlpha) * 50% - var(--step-4));
  --roofFront_b: calc(var(--roofFrontpageTanAlpha) * 50%);// - var(--step-6)
  --roofFront_a2-0: calc(var(--roofFrontpageTanAlpha) * (100 - var(--roofFrontTopX)) * 1%);
  --roofFront_a2: calc(var(--kvHeight) - var(--roofFront_a));

  --asideLeftWidth: calc(calc(calc(100vw - 70ch) / 2) + var(--step-4));
  --asideLeftHeight: calc(var(--roofFrontpageTanAlpha) * var(--asideLeftWidth));
  --intersectionLeft: calc(var(--roofFront_a2-0) - var(--asideLeftHeight));
  
  --frame-n: 9;  
  //--kvHeight: calc(100vw * (var(--frame-n) / 16));
  --a1: calc(var(--roofFrontpageTanBeta) * 33vw);
  --c3: calc(var(--step-6) + var(--step-7));
  --a3: calc(var(--roofFrontpageTanAlpha) * calc((67vw - var(--c3)))); 
  //--roofSpacingTop: calc(var(--kvHeight) - var(--a1));
  
 
  --footer-tanBeta1: #{tangens(36)};
  --footer-tanAlpha2: #{tangens(29)};
  --footer-b1: calc(var(--footer-tanBeta1) * 33vw);
  --footer-a2: calc(var(--footer-tanAlpha2) * 67vw);
  /*
  Breite content: calc(100vw - var(--max-width-ch))
  pading content: calc(var(--step-8) + var(--step-4))
  Breite content gesamt: calc(calc(100vw - var(--max-width-ch)) + calc(var(--step-8) + var(--step-4)))
  */
  --footer-body-width: calc(90ch + var(--step-8) + var(--step-4));//calc(100vw - 770px);//873px//56vw
  --footer-body-margin: calc(calc(100vw - var(--footer-body-width)) / 2);
  
  --footer-c3: var(--c3);//calc(var(--step-6) + var(--step-7));//calc(100vw + var(--step-8) - var(--footer-body-width) - var(--footer-body-margin));//calc(calc(100vw - calc(calc(100vw - var(--max-width-ch)) + calc(var(--step-8) + var(--step-4)))) / 2);
  --footer-a3: calc(var(--footer-tanAlpha2) * var(--footer-c3));
  --footer-contentSpacingTop: calc(var(--footer-a2) - var(--footer-a3));
/**  
          /\
         /γ \
        /    \
       /      \
    b /        \a
     /          \
    /            \
   /              \
  /α             β \
 - - - - - - - - - - 
           c

keyvisual:
Höhe gesamt (rechte Seite)): b = tan(beta) * c == tan(36) * 33 = 23.963 
Höhe linke Seite: a = tan(alpha) * c == tan(15) * 67 = 17.953 
Höhe Schnittpunkt content mit b: a = tan(15) * asideWidthLeft 

*/
   
  //https://png-pixel.com/
  --pixel1x1transparent: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
  --roof-bg-img: var(--pixel1x1transparent);
  --kv-bg-img: var(--pixel1x1transparent);
  --kv-bg-size: cover;
  --kv-bg-pos-x: 50%;
  --kv-bg-pos-y: 50%;
}

@include media-query('is-l') {
  :root {
    --c3: calc(calc(calc(100vw - 70ch) / 2) + var(--step-4));
    --footer-c3: var(--c3);
  }
}

/*
@include media-query('md') {
  :root {
    --roofFront_b: calc(var(--roofFrontpageTanAlpha) * 50% - var(--step-6));
  }
}*/
/*
@include media-query('sm') {
  :root {
    --asideLeftWidth: calc(calc(calc(100vw - 70ch) / 2) + var(--step-4));
  }
}
*/

@include media-query('mg') {
  :root {
    --service-n: 6;
  }
}
// slick
// -----------------------------------------------------------------------------
//$slick-font-path: "../../node_modules/slick-carousel/slick/fonts/slick" !default;
//$slick-font-path: "../fonts/slick/" !default;
$slick-font-family: var(--font-primary);
$slick-loader-path: "../img/vendor/slick/";
$slick-arrow-color: get-color('primary');
$slick-dot-color: get-color('primary');
$slick-dot-color-active: get-color('secondary');
$slick-prev-character: "\1438";//ᐸ Kanadische Syllabics Pa
$slick-next-character: "\1433";//ᐳ Kanadische Syllabics Po 
//$slick-dot-character: "\2022";//• Kugel 
//$slick-dot-size: get-size('4');
$slick-opacity-default: 1;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: .25;
