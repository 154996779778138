.c-accordion {
}

.c-accordion__panel {
  overflow: hidden;
  
  & + .c-accordion__panel {
    border-top: 1px solid get-color('grey-500');
  }  
  
  > summary {
      color: get-color('primary');
      cursor: pointer;
      list-style: none;
      padding: get-size('05') get-size('05') get-size('05') get-size('15'); 
      position: relative;

      // chrome & safari
      &::-webkit-details-marker {
        display: none;
      }
  
      &::before {
        content: '+';
        position: absolute;
        left: get-size('075');
      }
      
      .is-theme-button & {
        background-color: get-color('white');
        border-radius: 8px;
        display: inline-block;
        padding-right: 1.25rem;
      }
  }
  
  &[open] {
      > summary {
        @include apply-utility('font', 'primary-bold');
        background-color: get-color('secondary');
        border-radius: 0;
        color: get-color('black');
        display: block;
        padding-left: get-size('2'); 
        &::before {
            content: '-';
        }
      }
      
      > div {
        background-color: get-color('grey-100');
        opacity: 1;
      }
  }
  
  > div {//c-accordion__body
      padding: get-size('075') get-size('075') get-size('1') get-size('15');
      transition: .5s;
      opacity: 0;
      
      > * + h4 {
        margin-top: get-size('1');
      }
      
      > * + h3 {
        margin-top: get-size('2');
      }

      & > * + * {
        margin-top: get-size('05');
      }
  }
}
  