.c-roof .shape {
  .has-bg--roof-primary & {
    fill: get-color('primary');
  }

  .has-bg--roof-secondary & {
    fill: get-color('secondary');
  }

  .has-bg--roof-white & {
    fill: get-color('white');
  }

  .has-bg--roof-grey-900 & {
    fill: get-color('grey-900');
  }
}