@mixin u-cluster {
  //.u-cluster {
    --space: var(--step-0);
    overflow: hidden;
  //}

  //.u-cluster > * {
  & > * {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;//center;
    margin: calc(var(--space) / 2 * -1);
  }

  //.u-cluster > * > * {
  & > * > * {
    align-self: center;
    margin: calc(var(--space) / 2);
  }
  
}

.u-cluster {
  //@include u-cluster;
  display: flex;
  flex-wrap: wrap;
  gap: var(--step-0);
  justify-content: flex-start;
  align-items: stretch;//center;
}

@include media-query('lg') {
  .lg\:u-cluster {
    @include u-cluster;
  }
}

.u-cluster--nowrap-half > * {
  flex-wrap: nowrap;
}

.u-cluster--nowrap-half > * > * {
  //flex-basis: 50%;
  
  &:first-child {
    flex-grow: 1;
    //flex-shrink: 1;
  }
  &:last-child {
    flex-grow: 1;
    //flex-shrink: 0;
  }
}
