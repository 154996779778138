/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 7, 2021 */
@font-face {
    font-family: 'vollkornblack';
    src: url('../fonts/Vollkorn/vollkorn-black-webfont.woff2') format('woff2'),
         url('../fonts/Vollkorn/vollkorn-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vollkornblack_italic';
    src: url('../fonts/Vollkorn/vollkorn-blackitalic-webfont.woff2') format('woff2'),
         url('../fonts/Vollkorn/vollkorn-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vollkornbold_italic';
    src: url('../fonts/Vollkorn/vollkorn-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/Vollkorn/vollkorn-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vollkornitalic';
    src: url('../fonts/Vollkorn/vollkorn-italic-webfont.woff2') format('woff2'),
         url('../fonts/Vollkorn/vollkorn-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vollkornregular';
    src: url('../fonts/Vollkorn/vollkorn-regular-webfont.woff2') format('woff2'),
         url('../fonts/Vollkorn/vollkorn-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vollkornsemibold';
    src: url('../fonts/Vollkorn/vollkorn-semibold-webfont.woff2') format('woff2'),
         url('../fonts/Vollkorn/vollkorn-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vollkornsemibold_italic';
    src: url('../fonts/Vollkorn/vollkorn-semibolditalic-webfont.woff2') format('woff2'),
         url('../fonts/Vollkorn/vollkorn-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vollkornbold';
    src: url('../fonts/Vollkorn/vollkorn-bold-webfont.woff2') format('woff2'),
         url('../fonts/Vollkorn/vollkorn-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}