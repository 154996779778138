.c-headline {
  margin-bottom: get-size('1');
  position: relative;
  width: calc(100% - var(--step-4));
  z-index: 2;
  
  .is-hl-noflow & {
    position: absolute;
    
    --abstandOben: var(--step-8);
    @include media-query('lg') {
      --abstandOben: var(--step-7);
    }
    
    top: calc(calc(var(--roofSpacingTop) * -1) + var(--abstandOben));
  }
}

.c-headline__body {
  --border-opacity: .9;//get-config-value(90, 'opacity');
  border-color: rgba(get-color('secondary'), var(--border-opacity));
  border-left-width: get-size('0');
  border-left-style: solid;
  bottom: 0;
  position: relative;//absolute
  width: 100%;
  
  .is-pg-service-index & {
    position: static;
  }
}

/* Stellenangebot */
.c-headline__overline {
    @include media-query('lg') {
      top: 0.6rem;
      position: relative;
      left: -1rem;
    }  
}

.c-headline__h1 {  
  & > span {
    --bg-opacity: .9;//get-config-value(90, 'opacity');
    background-color: rgba(get-color('secondary'), var(--bg-opacity));
    display: inline-block;
    &:after {
      content: "\00A0";
    }
  }
}

.c-headline__post {
  a {
    color: get-color('white');
  }
}

.c-jobs-headline {
  position: relative;
  background: get-color('grey-300');
  padding: var(--step-2);
  left: calc(var(--step-2) * -1);
  top: calc(var(--step-2) * -1);
  z-index: 13;

  h1 {
    background: get-color('secondary');
    font-size: var(--step-3);
    margin-left: calc(var(--step-6) * -1);
    margin-top: var(--step--2);
    margin-bottom: var(--step--2);
    padding: var(--step-1) var(--step-2);
  }
}
