.c-ap {
  display: flex;
  
  & > div {
      border-radius: 100%;
      overflow: hidden;
      //width: get-size('5');
      margin-bottom: get-size('05');
      margin-right: get-size('075');
      flex-basis: get-size('5');

      & > .u-frame {
        --frame-n: 1;
        --frame-d: 1;
        line-height: var(--fluid-bp);

        & > img {
          object-position: 0 0;
        }
      }    
  }
}
