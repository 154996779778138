.footer-rect {
  position: absolute;
  top: 0;
  left: 0;
}

.footer-rect-1 {
  left: 67vw;
  width: 33vw;
  height: var(--footer-b1);
}

.footer-rect-2 {
  width: 67vw;
  height: var(--footer-a2);
}

.footer-rect-3 {
  left: 0;//83px;
  top: var(--footer-contentSpacingTop);
  height: var(--footer-a3);
  width: var(--footer-c3);
}



.c-footer {
  /*.c-roof {
    top: var(--step-1);
  }*/
  
  ul {
    ul {
      list-style: disc;
      //padding-left: 1rem;
    }

    & > li > a {
      color: get-color('white');
    }
  }
  
  .is-pg-frontpage & {
    background-color: get-color('grey-300');
  }
  
  @include media-query('lg') {
    &,
    .is-pg-frontpage & {
      background-color: get-color('grey-900');
    }
    margin-left: calc(50% - 50vw);
    width: 100vw;
    
    &.has-bg--roof-grey-900 {
      --roof-bg-img: none;
    }
    
    & > .u-filler {
      display: none;
    }
  }
}

.c-footer__body {
  //margin-top: calc(var(--roofHeight) - calc(var(--roofTan) * var(--sidebarWidth)));
  margin-top: calc(var(--footer-contentSpacingTop) + var(--step-2));// + Puffer
  padding-left: var(--footer-c3);
  max-width: 90vw;

  @include media-query('lg') {
    font-size: var(--step--1);
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 1200px;
    padding-left: 0;
    
    & > .u-stack {
      flex-direction: row;
      align-items: stretch;
      justify-content: center;
    }
    
    & > .u-stack-1 > * + * {
      margin-top: 0;
      margin-left: var(--step-2);
    }
    
    /*&.u-content-wrapper {
      padding-left: 0;
    }*/
  }
}
