.c-intro-container {
  background-color: get-color('grey-300');
  padding: var(--step-3) var(--step-3) var(--step-0) var(--step-3);
  transform: translateY(calc(var(--step-0) * -1));
  z-index: 1;
  
  &.u-content-wrapper  {
    max-width: var(--max-content-width);
  }
  
  & > article > p + * {
    margin-top: var(--step--3);
    //margin-top: get-size('05');    
  }
  
  .is-pg-service & {
      background-color: transparent;
      padding-bottom: 0;
      padding-right: 0;
      padding-top: 0;
      transform: translateY(0);
    }
}

@include media-query('lg') {
  .c-intro-container {
    background-color: get-color('grey-300');
    margin-right: var(--step-6);
    margin-left: var(--step-4);
    
    .is-pg-service & {
      background-color: get-color('grey-300');
      //padding-right: var(--step-3);
      padding-right: var(--step-4);
      padding-top: var(--step-3);
      transform: translateY(calc(var(--step-0) * -1));
      
      padding-left: var(--step-4);
      margin-left: 0;
      margin-right: calc(var(--step-4) * -1);
    }
  }  
}

@include media-query('mg') {
  .c-intro-container {
    .is-pg-service & {
      margin-right: var(--step-4);
    }
  }
}
