@include media-query('md-x') {
  .c-map {
    --frame-n: 6;
    flex: 0 0 50%;
  }
}
  
.c-map__overlay {  
  bottom: auto;
  left: auto;
  bottom: auto;
  top: var(--step-0);
  right: var(--step-0);
  
  font-size: var(--step--1);
  
  @include media-query('lg') {
    top: auto;
    bottom: var(--step-0);
  }
}

.c-map__address {
  color: get-color('white');
  font-size: var(--step-1);
  position: absolute;
  bottom: get-size('1');
  left: get-size('1');
  right: auto;
  top: auto;
}
