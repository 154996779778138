// clearfix
.u-clearfix {
  &:after {
    display: table;
    clear: both;
    content: ' ';
  }
}

// Hide visually and from screen readers
//»[aria-hidden="true"]« nicht verwenden; funktioniert nicht mit slick-slider
.u-hidden {
  display: none !important;
}

[hidden] {
  display: none;
}

// Hide only visually, but have it available for screen readers:
// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
.u-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// visuallyhiddenFocusable:
// Extends the .visuallyhidden class to allow the element
// to be focusable when navigated to via the keyboard:
// https://www.drupal.org/node/897638
.u-visuallyhidden--focusable {
  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

// invisible:
// Hide visually and from screen readers, but maintain layout
.u-invisible {
  visibility: hidden;
}

// Image-replacement:
// Hides text for image replacement
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757
.u-ir,
.u-hide-text {
  background-color: transparent;
  border: 0;
  color: transparent;
  font: #{0 / 0} a;
  text-shadow: none;
}

.u-nowrap {
  white-space: nowrap;
}

.u-bg {  
  background: {
    image: var(--roof-bg-img);
    position: 0 0;
    repeat: no-repeat;
    size: 100%;
  }
}

.u-bg-black {
  @include u-bg-color('black');
}

.u-filler {
  min-height: 1000vh;
  position: absolute;
  top: var(--roofHeightvw);
  width: 100%;
  z-index: 1;  
}

.u-right {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.u-center {
  justify-content: center;
  display: flex;
}
