.c-ap-site {
  display: block;
  position: fixed;
  bottom: auto;
  top: 100vh;
  //width: 66vw;
  z-index: 20;
  
  & > svg {
    position: absolute;
    height: 100%;
    
    .cls-1 {
      fill: get-color('primary');
    }
  }
}

.c-ap-site__label-wrapper {
  position: absolute;
  padding-bottom: 2rem;
  top: 0;//-2.5rem;
  
  & > svg {
    position:absolute;
 	bottom: 0.15rem;
	left: 0;
	height: 2rem;
  }
}

.c-ap-site__label {
  @include apply-utility('font', 'secondary-bold');
  background-color: get-color('primary');
  color: get-color('white');
}

.c-ap-site__content {
  width: 66%;
  
  a {
    color: get-color('white');
  }
}
