.c-tour-slider {
  --bg-opacity: .1;//get-config-value(10, 'opacity');
  background-color: rgba(get-color('primary'), var(--bg-opacity));
  
  .u-frame > * {
    top: auto;
  }
}

.c-tour-slide__body {
  padding: get-size('0') get-size('2') get-size('2');
  
  & > * {
    margin-top: get-size('05');    
  } 
}

@include media-query('lg') {
  .c-tour-slider {
    background-color: transparent;
  }
  
  .c-tour-slide__intermediate {
    display: flex;
    flex-wrap: nowrap;
    height: 560px;

    & > * {
      flex: 0 0 50%;
    }
    
    .u-frame {
      padding-bottom: 0;
      overflow: hidden;
    }

    img {
      height: 100%;
      object-fit: cover;
    }
    
    .c-tour-slide__body {
      --bg-opacity: .1;//get-config-value(10, 'opacity');
      background-color: rgba(get-color('primary'), var(--bg-opacity));
      font-size: get-size('-1');
      overflow-y: auto;
      p {
        padding-bottom: get-size('2');
      }
    }
  }
}
