.tbl-dates {
  border: 0 none;
  width: 100%;
  
  td {
    padding: .5em;
  }
}

.td-dates__label {
  background-color: get-color('primary');
  color: get-color('white');
  text-align: center;
  
  .tr-dates__even & {
    --bg-opacity: .5;//get-config-value(50, 'opacity');
    background-color: rgba(get-color('primary'), var(--bg-opacity));
    
    .is-mobile & {
      --bg-opacity: 1;//get-config-value(100, 'opacity');
    }
  }
}

.td-dates__time, 
.td-dates__event {
  border-top: 2px solid get-color('white');
  text-align: center;
  
  .tr-dates__odd & {
    --bg-opacity: .1;//get-config-value(10, 'opacity');
    background-color: rgba(get-color('primary'), var(--bg-opacity));
  }
  
  .tr-dates__even & {
    --border-opacity: .1;//get-config-value(10, 'opacity');
    border-color: rgba(get-color('primary'), var(--border-opacity));
  }  
}

.td-dates__event {
  text-align: left;
}
