.c-ct-video__figure {
  &::before {
    background-color: rgba(get-color('black'), .5);
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }  
}

.c-ct-video__caption-overlay {
  color: #fff;
  display: flex;
  //font-size: var(--step-1);
  flex-direction: column;
  padding: var(--step-1);
  text-align: center;
  z-index: 1;
  
  svg {
    flex: 0 0 auto;
    height: 2em;
    width: 2em;
    
  }
  
  &.is-empty-caption {
    svg {
      height: var(--step-8);
      width: var(--step-8);
    }
  }
  
  /*@include media-query('md') {
    font-size: var(--step-1);
  }*/
}