// Generated by Font Squirrel (http://www.fontsquirrel.com) on June 28, 2013 12:29:37 PM America/New_York

@font-face {
  font-family: 'AmbleLight';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Amble/Amble-Light-webfont.eot');
  src: url('../fonts/Amble/Amble-Light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Amble/Amble-Light-webfont.woff') format('woff'),
  url('../fonts/Amble/Amble-Light-webfont.ttf') format('truetype')/*,
  url('../fonts/Amble/Amble-Light-webfont.svg#AmbleLight') format('svg')*/;
}

@font-face {
  font-family: 'AmbleLightItalic';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Amble/Amble-LightItalic-webfont.eot');
  src: url('../fonts/Amble/Amble-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Amble/Amble-LightItalic-webfont.woff') format('woff'),
  url('../fonts/Amble/Amble-LightItalic-webfont.ttf') format('truetype')/*,
  url('../fonts/Amble/Amble-LightItalic-webfont.svg#AmbleLightItalic') format('svg')*/;
}

@font-face {
  font-family: 'AmbleRegular';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Amble/Amble-Regular-webfont.eot');
  src: url('../fonts/Amble/Amble-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Amble/Amble-Regular-webfont.woff') format('woff'),
  url('../fonts/Amble/Amble-Regular-webfont.ttf') format('truetype')/*,
  url('../fonts/Amble/Amble-Regular-webfont.svg#AmbleRegular') format('svg')*/;
}

@font-face {
  font-family: 'AmbleItalic';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Amble/Amble-Italic-webfont.eot');
  src: url('../fonts/Amble/Amble-Italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Amble/Amble-Italic-webfont.woff') format('woff'),
  url('../fonts/Amble/Amble-Italic-webfont.ttf') format('truetype')/*,
  url('../fonts/Amble/Amble-Italic-webfont.svg#AmbleItalic') format('svg');*/;
}

@font-face {
  font-family: 'AmbleBold';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Amble/Amble-Bold-webfont.eot');
  src: url('../fonts/Amble/Amble-Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Amble/Amble-Bold-webfont.woff') format('woff'),
  url('../fonts/Amble/Amble-Bold-webfont.ttf') format('truetype')/*,
  url('../fonts/Amble/Amble-Bold-webfont.svg#AmbleBold') format('svg')*/;
}

@font-face {
  font-family: 'AmbleBoldItalic';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Amble/Amble-BoldItalic-webfont.eot');
  src: url('../fonts/Amble/Amble-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Amble/Amble-BoldItalic-webfont.woff') format('woff'),
  url('../fonts/Amble/Amble-BoldItalic-webfont.ttf') format('truetype')/*,
  url('../fonts/Amble/Amble-BoldItalic-webfont.svg#AmbleBoldItalic') format('svg')*/;
}

@font-face {
  font-family: 'AmbleLightCondensed';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Amble/Amble-LightCondensed-webfont.eot');
  src: url('../fonts/Amble/Amble-LightCondensed-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Amble/Amble-LightCondensed-webfont.woff') format('woff'),
  url('../fonts/Amble/Amble-LightCondensed-webfont.ttf') format('truetype')/*,
  url('../fonts/Amble/Amble-LightCondensed-webfont.svg#AmbleLightCondensed') format('svg')*/;
}

@font-face {
  font-family: 'AmbleLightCondensedItalic';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Amble/Amble-LightCondensedItalic-webfont.eot');
  src: url('../fonts/Amble/Amble-LightCondensedItalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Amble/Amble-LightCondensedItalic-webfont.woff') format('woff'),
  url('../fonts/Amble/Amble-LightCondensedItalic-webfont.ttf') format('truetype')/*,
  url('../fonts/Amble/Amble-LightCondensedItalic-webfont.svg#AmbleLightCondensedItalic') format('svg')*/;
}
