// defaults
:root {
  --bg-opacity: 1;
  --border-opacity: 1;
}

*, ::after, ::before {
  border-color: get-color('grey-500');
  border-style: solid;
  border-width: 0;
}
