main {
  --kvHeight: calc(100vw * (var(--frame-n) / 16));
  --roofSpacingTop: calc(var(--kvHeight) - var(--a1));  
}

.is-kv-blank {
  --frame-n: 5;
}
  
.c-main-content-container {
  margin-top: var(--roofSpacingTop);//calc(var(--keyvisualHeight) - var(--roofFront_a));
  
  .is-kv-none &,
  .is-kv-no-display & {
    margin-top: var(--step-5);
  }
  
  .is-pg-frontpage & {
    margin-top: var(--roofSpacingTop);
  }
}

.c-main-content {
  padding-top: calc(var(--intersectionLeft) - var(--step-5));
  
  .is-pg-service-index &,
  .is-hl-noflow & {
    padding-top: calc(var(--a3) + var(--step-4));// Schnittpunkt plus Puffer
  }
  
  .is-pg-frontpage & {
    padding-top: var(--intersectionLeft);
    
    @include media-query('md-s') {
      padding-top: calc(var(--intersectionLeft) + var(--step-2));
    }
  }
}

.rect-3 {
  width: var(--c3);
}

.c-roof {
  position: absolute;
  width: 100%;
  z-index: 0;
  top: 0;
}

@include media-query('lg') {
  .c-main {
    background-color: get-color('white');
    padding-left: var(--step-6);
    padding-right: var(--step-6);
  }
  
  .c-roof {
    display: none;
  }
}

