.annotate {
  font-style: italic;
  color: #366ed4;
}

.listbox-area {
  padding: 20px;
  background: #eee;
  border: 1px solid #aaa;
  font-size: 0;
}

.left-area,
.right-area {
  box-sizing: border-box;
  display: inline-block;
  font-size: 14px;
  vertical-align: top;
  width: 50%;
}

.left-area {
  padding-right: 10px;
}

.right-area {
  padding-left: 10px;
}

[role="listbox"] {
  height: auto;
  //min-height: 18em;
  max-height: 25rem;
  padding: 0;
  background: get-color('white');
  border: 1px solid get-color('grey-500');
}

[role="option"] {
  display: block;
  padding: 0 1em 0 1.5em;
  position: relative;
  line-height: 1.8em;
}

[role="option"].focused {
  background-color: rgba(get-color('primary'), .1);//#bde4ff;
}

[role="option"][aria-selected="true"]::before {
  content: '\2713';
  position: absolute;
  left: 0.5em;
}

/*
button {
  font-size: 16px;
}
*/
button[aria-disabled="true"] {
  opacity: 0.5;
}

.move-right-btn {
  padding-right: 20px;
  position: relative;
}

.move-right-btn::after {
  content: ' ';
  height: 10px;
  width: 12px;
  //background-image: url('../imgs/Arrows-Right-icon.png');
  background-position: center right;
  position: absolute;
  right: 2px;
  top: 6px;
}

.move-left-btn {
  padding-left: 20px;
  position: relative;
}

.move-left-btn::after {
  content: ' ';
  height: 10px;
  width: 12px;
  //background-image: url('../imgs/Arrows-Left-icon.png');
  background-position: center left;
  position: absolute;
  left: 2px;
  top: 6px;
}

#ss_elem_list {
  max-height: 18em;
  overflow-y: auto;
  position: relative;
}

.c-listbox-button {
  background-color: transparent;
  border-radius: 0;
  color: get-color('grey-900');
  //font-size: 16px;
  text-align: left;
  //padding: 5px 10px;
  //width: 150px;
  position: relative;
  padding-right: 1em;
}

.c-listbox-button::after {
  width: 0;
  height: 0;
  border-left: .25em solid transparent;
  border-right: .25em solid transparent;
  border-top: .25em solid get-color('secondary');
  content: " ";
  position: absolute;
  right: .3em;
  top: .7em;
}

.c-listbox-button[aria-expanded="true"]::after {
  border-top: 0;
  border-bottom: .25em solid get-color('secondary');
}

.c-listbox-list {
  border-top: 0;
  font-size: get-size('2');
  min-width: 25rem;
  overflow-y: auto;
  position: absolute;
  margin: 0;
  white-space: nowrap;
  z-index: 10;
}

/*.hidden {
  display: none;
}*/

.toolbar {
  font-size: 0;
}

.toolbar-item {
  border: 1px solid #aaa;
  background: #ccc;
}

.toolbar-item[aria-disabled="false"]:focus {
  background-color: #eee;
}

.offscreen {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  font-size: 14px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
