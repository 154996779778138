/**.c-tabs {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-content-width);
  padding-left: var(--step-4);
  padding-right: var(--step-4);
  position: relative;
}*/

.tabs__tab-list {
  background-color: get-color('white');
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tabs__tab-list li {
  background-color: get-color('grey-100');
  border-right: 4px solid get-color('white');
  margin: 0;
  
  &:last-child {
    border-right-width: 0;
  }
}

.tabs__trigger {
  border-bottom: none;
  color: get-color('grey-700');
  display: block;
  font-weight: normal;
  margin: 0;//0 0 0 4px;
  padding: .5em 1em;
  text-decoration: none;
    
  &.is-selected {
    background-color: get-color('primary');
    color: get-color('white');
  }
}

.tabs__panel {
  background-color: get-color('primary');
  display: none;
  padding: var(--step-0) var(--step-0) var(--step--4) var(--step-0);

  .is-initialized & {
    display: inherit;
  }

  &.is-hidden {
    display: none;
  }
}

.tabs__item {
  border-radius: 4px;
  background-color: #fff;
  list-style-type: none;
  margin-bottom: var(--step-0);
  overflow: hidden;
}

.tabs__item-link {
  color: #333;
  display: block;
  padding: .5em 1em .5em 1.5em;
  position: relative;
  text-decoration: none;
  
  &:before {
	color: #f90;
	content: "■";
	position: absolute;
	left: .5em;
  }
}

@include media-query('md-s') {
  .tabs__panel {
    padding-bottom: 0;
  }
  
  .tabs__list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
  }
  
  .tabs__item {
    flex: 1 0 40%;
    margin-bottom: var(--step-0);
    
    &:nth-child(2n) {
      margin-left: var(--step-0);
    }
  }
}

@include media-query('cw') {
  .tabs__panel {
    padding-left: 0;
  }
  
  .tabs__item {
    flex: 1 0 30%;
    
    &:nth-child(n+1) {
      margin-left: var(--step-0);
    }
  }
}