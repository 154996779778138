.c-fp-services__body {
  @include media-query('md-x') {
	display: flex;
	flex-wrap: nowrap;
	margin-left: calc(var(--step-0) * -1);
    
    & > div {
      flex: 0 1 50%;
      margin-left: var(--step-0);
    }
  }  
}

.c-fp-services__acc {
  background-color: get-color('grey-100');
  margin-top: var(--step-2);
  
  @include media-query('md-x') {
    margin-top: 0;
  }
}

//.c-accordion__panel {
.c-fp-service-acc__detail {
  overflow: hidden;
  
  //& + .c-accordion__panel {
  & + .c-fp-service-acc__detail {
    border-top: 2px solid get-color('white');
  } 
  
  > summary {
      color: get-color('primary');
      cursor: pointer;
      list-style: none;
      padding: get-size('05') get-size('05') get-size('05') get-size('15'); 
      position: relative;

      // chrome & safari
      &::-webkit-details-marker {
        display: none;
      }
  
      &::before {
        content: '+';
        position: absolute;
        left: get-size('075');
      }     
  }
  
  &[open] {
      > summary {
        @include apply-utility('font', 'primary-bold');
        background-color: get-color('secondary');
        color: get-color('black');
        padding-left: get-size('2'); 
        &::before {
            content: '-';
        }
      }
      
      > div {
        //background-color: get-color('white');
        opacity: 1;
      }
  }
  
  > div {//c-accordion__body
      font-size: var(--step--1);
      padding: get-size('075') get-size('075') get-size('1') get-size('15');
      text-align: right;
      transition: .5s;
      opacity: 0;
      
      > * + h4 {
        margin-top: get-size('1');
      }

      & > * + * {
        margin-top: get-size('05');
      }
      
      & > p {
        text-align: left;
      }
  }
}

.c-fp__media {
  & > * + * {
    margin-top: var(--step--1);
  }
}
 
.c-fp__figure::before {
  background-color: rgba(get-color('black'), .5);
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.c-fp__caption {
  color: #fff;
  display: flex;
  font-size: var(--step-1);
  flex-direction: column;
  padding: var(--step-1);
  text-align: center;
  z-index: 1;
  
  svg {
    flex: 0 0 auto;
    height: 2em;
    width: 2em;
  }
}

@include media-query('md') {
  .c-fp__media {
    display: flex;
    margin-left: calc(var(--step-0) * -1);
    
    & > * {
      margin-left: var(--step-0);
      flex: 0 0 calc(50% - var(--step-0));
    }
    
    & > * + * {
      margin-top: 0;
    }
  }
  
  .c-fp__caption {
    font-size: var(--step-0);
  }
}

.c-fp-partner {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  margin-left: calc(var(--step-2) * -1);
}

.c-fp-partner__link {
  color: get-color('grey-900');
  font-size: var(--step--1);
  margin-bottom: var(--step-0);
  margin-left: var(--step-2);
  text-decoration: none;
  flex: 0 1 calc(33.333% - var(--step-2));
  //max-width: 33.333%;
}

.c-fp-partner__figure {}
.c-fp-partner__caption {
  display: inline-block;
  margin-bottom: var(--step--4);
}

@include media-query('md-s') {
  .c-fp-partner {
    justify-content: flex-start;
    margin-left: calc(var(--step-2) * -1);
  }
  
  .c-fp-partner__link {
    margin-left: var(--step-2);
    flex: 0 1 calc(25% - var(--step-2));
  }
}

@include media-query('md-x') {  
  .c-fp-partner__link {
    flex-basis: calc(20% - var(--step-2));
  }
}
