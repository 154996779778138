.c-site-header {
  display: flex;
  justify-content: space-between;
}

.c-site-header--front {
  align-items: flex-end;

  .c-header__brand {
    display: flex;
    align-items: baseline;
  }

  .c-header__claim {
    line-height: 1.2;
  }
}

.c-site-header--default {
  align-items: center;
  top: 0;
  left: 0;
  
  @include media-query('lg') {
    //flex-wrap: wrap;
    align-items: flex-end;
    
    .c-header__brand-wrapper {
      flex: 0 1 auto;
      padding-bottom: var(--step--1);
    }
  }
  
  .c-header__brand {
    //font-size: 100%;
    width: calc(var(--sidebarWidth) * 1.25);
    
    @include media-query('lg') {
      font-size: 80%;
      display: flex;
      align-items: baseline;
      transition: ease .25s;
      width: auto;
    }
    
    @include media-query('mg') {
      font-size: 100%;
    }
  }

  .c-header__claim {
    font-size: 1.6em;
    line-height: 1.2;
  }
}

.c-site-logo {
  --logo-typo: #{get-color('black')};
  --logo-point: #{get-color('warn')};
  --logo-roof: #{get-color('primary')};
  transition: ease .25s;
    
  &.is-negative {
  --logo-typo: #{get-color('white')};
  --logo-point: #{get-color('white')};
  --logo-roof: #{get-color('white')};
  }

  &.is-negative-menu {
  --logo-typo: #{get-color('white')} !important;
  --logo-point: #{get-color('white')} !important;
  --logo-roof: #{get-color('white')} !important;
  }

  &.is-positive {
    --logo-typo: #{get-color('black')};
    --logo-point: #{get-color('warn')};
    --logo-roof: #{get-color('primary')};
  }

  .logo-typo {
    fill: var(--logo-typo);
  }

  .logo-roof {
    fill: var(--logo-roof);
  }

  .logo-point {
    fill: var(--logo-point);
  }

  @include media-query('lg') {
    transition: none;
    &.is-negative {
      --logo-typo: #{get-color('black')};
      --logo-point: #{get-color('warn')};
      --logo-roof: #{get-color('primary')};
    }
  }
  
  width: auto;
  height: get-size('4');
  
  .c-site-header--default & {
    height: get-size('6');
    
    @include media-query('lg') {
      height: 3em;
    }
  }
}

.c-site-header--default {  
  &.is-scrolled {
    padding-top: get-size('-2');
    padding-bottom: 0;//get-size('-4');
    
    .c-header__brand {
      .c-site-logo {
        height: get-size('4');
      }
    }

    @include media-query('lg') {
      .c-header__brand {
        font-size: 70%;
        width: auto;
        
        .c-site-logo {
          height: 3em;
        }
      }
    }
  }
}
