@mixin u-bg-opacity($opacity) {
  $o: get-config-value($opacity, 'opacity');
  --bg-opacity: #{$o};
}

@mixin u-bg-color($color) {
  background-color: rgba(get-color($color), var(--bg-opacity));
}

@mixin u-border-opacity($opacity) {
  $o: get-config-value($opacity, 'opacity');
  --border-opacity: #{$o};
}

@mixin u-border-color($color) {
  border-color: rgba(get-color($color), var(--border-opacity));
}
