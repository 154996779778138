@mixin reset-symmetrical-padding {
  padding-left: calc(var(--step-6) + var(--step-7));
  padding-right: var(--step-6);  
}

.u-wrapper {
  //box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width);
  //display:flex;
  //flex-direction: column;
  //align-items: center;
  position: relative;
}

.u-wrapper-content {
  margin-left: auto;
  margin-right: auto;
  //max-width: 75ch;
  //max-width: var(--max-width);
  padding-left: calc(var(--step-6) + var(--step-7));
  padding-right: var(--step-6);
  
  .sm\:is-sym &, 
  .md-s\:is-sym &, 
  .md\:is-sym & {
    padding-left: var(--step-6);
    padding-right: var(--step-6);
  }
  
  @include media-query('sm') {
    .sm\:is-sym & {
      @include reset-symmetrical-padding;
    }
  }
  
  @include media-query('md-s') {
    .md-s\:is-sym & {
      @include reset-symmetrical-padding;
    }
  }
  
  @include media-query('md') {
    .md\:is-sym & {
      @include reset-symmetrical-padding;
    }
  }
}

.u-wrapper-body {
  //--max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  
  @supports (max-width: 60ch) {
    --max-width: 60ch;
  }
  
  &.is-wide {
    --max-width: 1440px;

    @supports (max-width: 90ch) {
      --max-width: 90ch;
    }
  }
  
  max-width: var(--max-width);
  
  /**.is-pg-news & {
    padding-left: var(--step-4);
    padding-right: var(--step-4);
  }*/
}

/* deprecated
--------------------------------------------------------------------------------
*/
.u-wrapper--inner {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-content-width);
  padding-left: var(--step-4);
  padding-right: var(--step-4);
}
