.c-sitemap__list {
  list-style: inherit;
}

.c-sitemap__item {
  &.is-level1 {
    margin-top: var(--step-0);
  }

  &.is-level2 {
    margin-left: var(--step-0);
    &.has-entries {
      @include apply-utility('font', 'primary-bold');
      margin-top: var(--step--1);
    }
  }

  &.is-level3 {
    @include apply-utility('font', 'primary');
    margin-left: var(--step-0);
  }
}