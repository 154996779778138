.c-links {
  margin-top: var(--step-4);
}

.c-link {
  display: flex;
  flex-direction: column-reverse;
  
  & + .c-link {
    border-top: 1px solid get-color('grey-500');
    margin-top: var(--step-0);
    padding-top: var(--step-0);
  }
}

.c-link__media {
  margin-bottom: var(--step-0);
  width: 50%;
}

@include media-query('sm') {
  .c-link {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .c-link__media {
    margin-bottom: 0;
    width: 33.333%;
  }
}