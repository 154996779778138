//@use "sass:map";

:root {
  --font-primary: AmbleRegular, Helvetica, Arial, sans-serif;
  --font-primary-bold: AmbleBold, Helvetica, Arial, sans-serif;
  --font-primary-light: AmbleLight, Helvetica, Arial, sans-serif;
  --font-primary-italic: AmbleItalic, Helvetica, Arial, sans-serif;
  --font-primary-light-italic: AmbleLightItalic, Helvetica, Arial, sans-serif;
  --font-primary-bold-italic: AmbleBoldItalic, Helvetica, Arial, sans-serif;
  --font-secondary: vollkornregular, Helvetica, Arial, sans-serif;
  --font-secondary-italic: vollkornitalic, Helvetica, Arial, sans-serif;
  --font-secondary-bold: vollkornbold, Helvetica, Arial, sans-serif;
  --font-secondary-bold-italic: vollkornbold_italic, Helvetica, Arial, sans-serif;
  --font-secondary-black: vollkornblack, Helvetica, Arial, sans-serif;
  --font-secondary-black-italic: vollkornblack_italic, Helvetica, Arial, sans-serif;
  --gorko-base-size: 1rem;
  
  --max-width: 1600px;
  --max-width-ch: 90ch;
  --max-content-width: 1440px;
  
  --bp-sm: 30em;//480px
  --bp-md-s: 40em;//640px
  --bp-md: 48em;//768px
  --bp-md-x: 64em;//1024px
  --bp-cw: 90em;//75em;//cw = content-width, 1200px
  --bp-intersectionLeft: 70.5em;//1128px
  --bp-lg: 90em;//1440px
  --bp-mg: 100em;//1600px
  --bp-wd: 120em;//1920px
  --bp-wd-x: 138em;//2208px
}

/* @link https://utopia.fyi/generator?c=320,16,1.2,1440,24,1.333,8,2, */
:root {
  --fluid-min-width: 320;
  --fluid-max-width: 1600;
  --fluid-min-size: 14;
  --fluid-max-size: 21;
  --fluid-min-ratio: 1.2;
  --fluid-max-ratio: 1.333;

  --fluid-screen: 100vw;
  --fluid-bp: calc(
    (var(--fluid-screen) - ((var(--fluid-min-width) / 16) * 1rem)) /
      ((var(--fluid-max-width) / 16) - (var(--fluid-min-width) / 16))
  );
}

@media screen and (min-width: 1600px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);
  }
}

:root {
  --fluid-max-negative: (1 / var(--fluid-max-ratio) / var(--fluid-max-ratio));
  --fluid-min-negative: (1 / var(--fluid-min-ratio) / var(--fluid-min-ratio));
  
  --fluid-min-scale--4: var(--fluid-min-scale--3) * var(--fluid-min-ratio) * var(--fluid-min-negative);
  --fluid-max-scale--4: var(--fluid-max-scale--3) * var(--fluid-max-ratio) * var(--fluid-max-negative);
  --fluid-min-size--4: (var(--fluid-min-size) * var(--fluid-min-scale--4)) / 16;
  --fluid-max-size--4: (var(--fluid-max-size) * var(--fluid-max-scale--4)) / 16;
  --step--4: calc(((var(--fluid-min-size--4) * 1rem) + (var(--fluid-max-size--4) - var(--fluid-min-size--4)) * var(--fluid-bp))); 
  
  --fluid-min-scale--3: var(--fluid-min-scale--2) * var(--fluid-min-ratio) * var(--fluid-min-negative);
  --fluid-max-scale--3: var(--fluid-max-scale--2) * var(--fluid-max-ratio) * var(--fluid-max-negative);
  --fluid-min-size--3: (var(--fluid-min-size) * var(--fluid-min-scale--3)) / 16;
  --fluid-max-size--3: (var(--fluid-max-size) * var(--fluid-max-scale--3)) / 16;
  --step--3: calc(((var(--fluid-min-size--3) * 1rem) + (var(--fluid-max-size--3) - var(--fluid-min-size--3)) * var(--fluid-bp))); 
  
  --fluid-min-scale--2: var(--fluid-min-scale--1) * var(--fluid-min-ratio) * var(--fluid-min-negative);
  --fluid-max-scale--2: var(--fluid-max-scale--1) * var(--fluid-max-ratio) * var(--fluid-max-negative);
  --fluid-min-size--2: (var(--fluid-min-size) * var(--fluid-min-scale--2)) / 16;
  --fluid-max-size--2: (var(--fluid-max-size) * var(--fluid-max-scale--2)) / 16;
  --step--2: calc(((var(--fluid-min-size--2) * 1rem) + (var(--fluid-max-size--2) - var(--fluid-min-size--2)) * var(--fluid-bp))); 
  
  --fluid-min-scale--1: var(--fluid-min-ratio) * var(--fluid-min-negative);
  --fluid-max-scale--1: var(--fluid-max-ratio) * var(--fluid-max-negative);
  --fluid-min-size--1: (var(--fluid-min-size) * var(--fluid-min-scale--1)) / 16;
  --fluid-max-size--1: (var(--fluid-max-size) * var(--fluid-max-scale--1)) / 16;
  --step--1: calc(((var(--fluid-min-size--1) * 1rem) + (var(--fluid-max-size--1) - var(--fluid-min-size--1)) * var(--fluid-bp)));
  
  --fluid-min-scale-0: var(--fluid-min-ratio);
  --fluid-max-scale-0: var(--fluid-max-ratio);
  --fluid-min-size-0: (var(--fluid-min-size)) / 16;
  --fluid-max-size-0: (var(--fluid-max-size)) / 16;
  --step-0: calc(((var(--fluid-min-size-0) * 1rem) + (var(--fluid-max-size-0) - var(--fluid-min-size-0)) * var(--fluid-bp)));
  
  --fluid-min-scale-1: var(--fluid-min-scale-0) * var(--fluid-min-ratio);
  --fluid-max-scale-1: var(--fluid-max-scale-0) * var(--fluid-max-ratio);
  --fluid-min-size-1: (var(--fluid-min-size) * var(--fluid-min-scale-0)) / 16;
  --fluid-max-size-1: (var(--fluid-max-size) * var(--fluid-max-scale-0)) / 16;
  --step-1: calc(((var(--fluid-min-size-1) * 1rem) + (var(--fluid-max-size-1) - var(--fluid-min-size-1)) * var(--fluid-bp)));

  --fluid-min-scale-2: var(--fluid-min-scale-1) * var(--fluid-min-ratio);
  --fluid-max-scale-2: var(--fluid-max-scale-1) * var(--fluid-max-ratio);
  --fluid-min-size-2: (var(--fluid-min-size) * var(--fluid-min-scale-1)) / 16;
  --fluid-max-size-2: (var(--fluid-max-size) * var(--fluid-max-scale-1)) / 16;
  --step-2: calc(((var(--fluid-min-size-2) * 1rem) + (var(--fluid-max-size-2) - var(--fluid-min-size-2)) * var(--fluid-bp)));

  --fluid-min-scale-3: var(--fluid-min-scale-2) * var(--fluid-min-ratio);
  --fluid-max-scale-3: var(--fluid-max-scale-2) * var(--fluid-max-ratio);
  --fluid-min-size-3: (var(--fluid-min-size) * var(--fluid-min-scale-2)) / 16;
  --fluid-max-size-3: (var(--fluid-max-size) * var(--fluid-max-scale-2)) / 16;
  --step-3: calc(((var(--fluid-min-size-3) * 1rem) + (var(--fluid-max-size-3) - var(--fluid-min-size-3)) * var(--fluid-bp)));

  --fluid-min-scale-4: var(--fluid-min-scale-3) * var(--fluid-min-ratio);
  --fluid-max-scale-4: var(--fluid-max-scale-3) * var(--fluid-max-ratio);
  --fluid-min-size-4: (var(--fluid-min-size) * var(--fluid-min-scale-3)) / 16;
  --fluid-max-size-4: (var(--fluid-max-size) * var(--fluid-max-scale-3)) / 16;
  --step-4: calc(((var(--fluid-min-size-4) * 1rem) + (var(--fluid-max-size-4) - var(--fluid-min-size-4)) * var(--fluid-bp)));

  --fluid-min-scale-5: var(--fluid-min-scale-4) * var(--fluid-min-ratio);
  --fluid-max-scale-5: var(--fluid-max-scale-4) * var(--fluid-max-ratio);
  --fluid-min-size-5: (var(--fluid-min-size) * var(--fluid-min-scale-4)) / 16;
  --fluid-max-size-5: (var(--fluid-max-size) * var(--fluid-max-scale-4)) / 16;
  --step-5: calc(((var(--fluid-min-size-5) * 1rem) + (var(--fluid-max-size-5) - var(--fluid-min-size-5)) * var(--fluid-bp)));
  
  --fluid-min-scale-6: var(--fluid-min-scale-5) * var(--fluid-min-ratio);
  --fluid-max-scale-6: var(--fluid-max-scale-5) * var(--fluid-max-ratio);
  --fluid-min-size-6: (var(--fluid-min-size) * var(--fluid-min-scale-5)) / 16;
  --fluid-max-size-6: (var(--fluid-max-size) * var(--fluid-max-scale-5)) / 16;
  --step-6: calc(((var(--fluid-min-size-6) * 1rem) + (var(--fluid-max-size-6) - var(--fluid-min-size-6)) * var(--fluid-bp)));

  --fluid-min-scale-7: var(--fluid-min-scale-6) * var(--fluid-min-ratio);
  --fluid-max-scale-7: var(--fluid-max-scale-6) * var(--fluid-max-ratio);
  --fluid-min-size-7: (var(--fluid-min-size) * var(--fluid-min-scale-6)) / 16;
  --fluid-max-size-7: (var(--fluid-max-size) * var(--fluid-max-scale-6)) / 16;
  --step-7: calc(((var(--fluid-min-size-7) * 1rem) + (var(--fluid-max-size-7) - var(--fluid-min-size-7)) * var(--fluid-bp)));

  --fluid-min-scale-8: var(--fluid-min-scale-7) * var(--fluid-min-ratio);
  --fluid-max-scale-8: var(--fluid-max-scale-7) * var(--fluid-max-ratio);
  --fluid-min-size-8: (var(--fluid-min-size) * var(--fluid-min-scale-7)) / 16;
  --fluid-max-size-8: (var(--fluid-max-size) * var(--fluid-max-scale-7)) / 16;
  --step-8: calc(((var(--fluid-min-size-8) * 1rem) + (var(--fluid-max-size-8) - var(--fluid-min-size-8)) * var(--fluid-bp)));
}

/**
 * BASE SIZE
 * All calculations are based on this. It’s recommended that
 * you keep it at 1rem because that is the root font size. You
 * can set it to whatever you like and whatever unit you like.
 */
$gorko-base-size: 1rem;

/**
 * SIZE SCALE
 * This is a Major Third scale that powers all the utilities that
 * it is relevant for (font-size, margin, padding). All items are
 * calcuated off the base size, so change that and cascade across
 * your whole project.
 */
//$gorko-ratio: 1.333;

$gorko-size-scale: (
  '-4': var(--step--4),
  '-3': var(--step--3),
  '-2': var(--step--2),
  '-1': var(--step--1),
  '0': var(--step-0),
  '025': calc(var(--step-0) * .25),
  '05': calc(var(--step-0) * .5),
  '075': calc(var(--step-0) * .75),
  '1': var(--step-1),
  '15': calc(var(--step-1) * 1.5),
  '2': var(--step-2),
  '3': var(--step-3),
  '4': var(--step-4),
  '5': var(--step-5),
  '6': var(--step-6),
  '7': var(--step-7),
  '8': var(--step-8),
  'zero': 0
);

$gorko-size-scale-important: (
  '-4': var(--step--4) !important,
  '-3': var(--step--3) !important,
  '-2': var(--step--2) !important,
  '-1': var(--step--1) !important,
  '0': var(--step-0) !important,
  '025': calc(var(--step-0) * .25) !important,
  '05': calc(var(--step-0) * .5) !important,
  '075': calc(var(--step-0) * .75) !important,
  '1': var(--step-1) !important,
  '15': calc(var(--step-1) * 1.5) !important,
  '2': var(--step-2) !important,
  '3': var(--step-3) !important,
  '4': var(--step-4) !important,
  '5': var(--step-5) !important,
  '6': var(--step-6) !important,
  '7': var(--step-7) !important,
  '8': var(--step-8) !important,
  '10': calc(var(--step-5) * 2) !important,
  '12': calc(var(--step-6) * 2) !important,
  '14': calc(var(--step-7) * 2) !important,
  'zero': 0 !important
);

/** 
 * COLORS
 * Colors are shared between backgrounds and text by default. 
 * You can also use them to power borders, fills or shadows, for example.
 */
$gorko-colors: (
  'primary': #3467cc, //$daheim-blau
  'secondary': #f90, //$daheim-orange und $orange
  'secondary-shade': #ff5151,
  'tertiary': #2c988c,
  'tertiary-glare': #d6fff6,
  'quaternary': #2374ab,
  'ok': #008000,
  'grey': #c4c4c4,
  'white': #ffffff,
  'grey-100': #f5f5f5,
  'grey-300': #eeeeee,
  'grey-500': #c4c4c4,
  'grey-700': #666666,
  'grey-900': #333333,
  'black': #000000,
  'warn': #cc0000,
  'none': transparent,
  'primary-100': rgba(#3467cc, 0.1),
  'primary-300': rgba(#3467cc, 0.3),
);

$gorko-bg-colors: (
  'primary': rgba(map-get($gorko-colors, 'primary'), var(--bg-opacity)),
  'secondary': rgba(map-get($gorko-colors, 'secondary'), var(--bg-opacity)),
  'secondary-shade': rgba(map-get($gorko-colors, 'secondary-shade'), var(--bg-opacity)),
  'tertiary': rgba(map-get($gorko-colors, 'tertiary'), var(--bg-opacity)),
  'tertiary-glare': rgba(map-get($gorko-colors, 'tertiary-glare'), var(--bg-opacity)),
  'quaternary': rgba(map-get($gorko-colors, 'quaternary'), var(--bg-opacity)),
  'ok': rgba(map-get($gorko-colors, 'ok'), var(--bg-opacity)),
  'grey': rgba(map-get($gorko-colors, 'grey'), var(--bg-opacity)),
  'white': rgba(map-get($gorko-colors, 'white'), var(--bg-opacity)),
  'grey-100': rgba(map-get($gorko-colors, 'grey-100'), var(--bg-opacity)),
  'grey-300': rgba(map-get($gorko-colors, 'grey-300'), var(--bg-opacity)),
  'grey-500': rgba(map-get($gorko-colors, 'grey-500'), var(--bg-opacity)),
  'grey-700': rgba(map-get($gorko-colors, 'grey-700'), var(--bg-opacity)),
  'grey-900': rgba(map-get($gorko-colors, 'grey-900'), var(--bg-opacity)),
  'black': rgba(map-get($gorko-colors, 'black'), var(--bg-opacity)),
  'warn': rgba(map-get($gorko-colors, 'warn'), var(--bg-opacity)),
  'none': rgba(map-get($gorko-colors, 'none'), var(--bg-opacity))
);

$gorko-fonts: (
  'primary': var(--font-primary),
  'primary-bold': var(--font-primary-bold),
  'primary-light': var(--font-primary-light),
  'primary-italic': var(--font-primary-italic),
  'primary-light-italic': var(--font-primary-light-italic),
  'primary-bold-italic': var(--font-primary-bold-italic),
  'secondary': var(--font-secondary),
  'secondary-italic': var(--font-secondary-italic),
  'secondary-bold': var(--font-secondary-bold),
  'secondary-bold-italic': var(--font-secondary-bold-italic),
  'secondary-black': var(--font-secondary-black),
  'secondary-black-italic': var(--font-secondary-black-italic)
);

$gorko-opacity: (
  0 : 0,
  10 : 0.1,
  20 : 0.2,
  30 : 0.3,
  40 : 0.4,
  50 : 0.5,
  60 : 0.6,
  70 : 0.7,
  75 : 0.75,
  80 : 0.8,
  90 : 0.9,
  100 : 1,
);

$gorko-widths: (
  '0': '0',
  '10': '10%',
  '20': '20%',
  '30': '30%',
  '40': '40%',
  '50': '50%',
  '60': '60%',
  '70': '70%',
  '80': '80%',
  '90': '90%',
  '100': '100%',
  'auto': 'auto',
  'full-width': '100%',
);

/**
 * CORE CONFIG
 * This powers everything from utility class generation to breakpoints
 * to enabling/disabling pre-built components/utilities.
 */
$gorko-config: (
  'align-items': (
    'items': (
      'end': 'flex-end',
      'start': 'flex-start',
      'stretch': 'stretch',
      'center': 'center',
    ),
    'output': 'responsive',
    'property': 'align-items'
  ),
  'align-self': (
    'items': (
      'end': 'flex-end',
      'start': 'flex-start',
      'stretch': 'stretch',
      'center': 'center',
    ),
    'output': 'responsive',
    'property': 'align-self'
  ),
  'bg': (
    'items': $gorko-bg-colors,
    'output': 'responsive',
    'property': 'background-color'
  ),
  'bg-opacity': (
    'items': $gorko-opacity,
    'output': 'standard',
    'property': '--bg-opacity'
  ),
  'border': (
    'items': (
      '0': '0',
      '1': '1px',
      '2': '2px',
      '3': '3px',
      '4': '4px',
      '6': '6px',
      '8': '8px'
    ),
    'output': 'responsive',
    'property': 'border-width'
  ),
  'border-bottom': (
    'items': (
      '0': '0',
      '1': '1px',
      '2': '2px',
      '3': '3px',
      '4': '4px',
      '6': '6px',
      '8': '8px'
    ),
    'output': 'responsive',
    'property': 'border-bottom-width'
  ),
  'border-top': (
    'items': (
      '0': '0',
      '1': '1px',
      '2': '2px',
      '3': '3px',
      '4': '4px',
      '6': '6px',
      '8': '8px'
    ),
    'output': 'responsive',
    'property': 'border-top-width'
  ),
  'border-color': (
    'items': $gorko-colors,
    'output': 'standard',
    'property': 'border-color'
  ),
  'border-radius': (
    'items': (
      '0': '0',
      '2': '2px',
      '3': '3px',
      '4': '4px',
      '6': '6px',
      '8': '8px',
      '12': '12px',
      '100': '100%'
    ),
    'output': 'responsive',
    'property': 'border-radius'
  ),
  'border-style': (
    'items': (
      'solid': 'solid'
    ),
    'output': 'standard',
    'property': 'border-style'
  ),
  'bottom': (
    'items': $gorko-widths,
    'output': 'standard',
    'property': 'bottom'
  ),
  'color': (
    'items': $gorko-colors,
    'output': 'standard',
    'property': 'color'
  ),
  'clear': (
    'items': (
      'both': 'both',
      'left': 'left',
      'right': 'right'
    ),
    'output': 'standard',
    'property': 'clear'
  ),
  'cursor': (
    'items': ('pointer': 'pointer'),
    'output': 'standard',
    'property': 'cursor'
  ),
  'display': (
    'items': (
      'block': 'block', 
      'inline-block': 'inline-block', 
      'inline': 'inline',
      'none': 'none',
      'flex': 'flex',
    ),
    'output': 'responsive',
    'property': 'display'
  ),
  'flex': (
    'items': (
      '0': '0 1 0%',
      '0-1-auto': '0 1 auto',
      '0-1-third': '0 1 30%',
      '0-1-quarter': '0 1 25%',
      '0-1-half': '0 1 50%',
      '1-1-auto': '1 1 auto',
      '1-0-auto': '1 0 auto',
      '0-0-auto': '0 0 auto',
      '1-1-sixth': '1 1 16.667%',
      '1-1-fifth': '1 1 20%',
      '1-1-quarter': '1 1 25%',
      '1-0-third': '1 0 33.33%',
      '1-1-third': '1 1 30%',
      '1-1-twofifth': '1 1 40%',
      '1-1-half': '1 1 45%',
      '1-0-twothird': '1 0 66.67%',
      '1-1-twothird': '1 1 66.67%',
      '1-1-threequarter': '1 1 75%',
      '1-1-full': '1 1 100%',
      '1': '1',
      '2': '2',
      '3': '3',
    ),
    'output': 'responsive',
    'property': 'flex'
  ),
  'flex-direction': (
    'items': (
      'row': 'row',
      'column': 'column',
      'row-reverse': 'row-reverse',
    ),
    'output': 'responsive',
    'property': 'flex-direction'
  ),
  'flex-wrap': (
    'items': (
      'wrap': 'wrap',
      'no-wrap': 'nowrap'
    ),
    'output': 'responsive',
    'property': 'flex-wrap'
  ),
  'font': (
    'items': $gorko-fonts,
    'output': 'standard',
    'property': 'font-family'
  ),
  'font-size': (
    'items': $gorko-size-scale,
    'output': 'responsive',
    'property': 'font-size'
  ),
  'font-style': (
    'items': (
      'normal': 'normal'
    ),
    'output': 'standard',
    'property': 'font-style'
  ),
  'gap': (
    'items': $gorko-size-scale,
    'output': 'responsive',
    'property': 'gap'
  ),
  'justify-content': (
    'items': (
      'space-between': 'space-between',
      'center': 'center',
    ),
    'output': 'responsive',
    'property': 'justify-content'
  ),
  'lh': (
    'items': $gorko-size-scale,
    'output': 'responsive',
    'property': 'line-height'
  ),
  'ol': (
    'items': (
      'upper-roman': 'upper-roman',
      'lower-alpha': 'lower-alpha',
      'decimal': 'decimal !important',
    ),
    'output': 'responsive',
    'property': 'list-style-type'
  ),
  'mt': (
    'items': $gorko-size-scale-important,
    'output': 'responsive',
    'property': 'margin-top'
  ),
  'mb': (
    'items': $gorko-size-scale-important,
    'output': 'responsive',
    'property': 'margin-bottom'
  ),
  'mr': (
    'items': $gorko-size-scale-important,
    'output': 'responsive',
    'property': 'margin-right'
  ),
  'ml': (
    'items': $gorko-size-scale-important,
    'output': 'responsive',
    'property': 'margin-left'
  ),
  'opacity': (
    'items': $gorko-opacity,
    'output': 'standard',
    'property': 'opacity'
  ),
  'overflow': (
    'items': ('hidden' : 'hidden'),
    'output': 'standard',
    'property': 'overflow'
  ),
  'overflow-x': (
    'items': ('hidden' : 'hidden'),
    'output': 'standard',
    'property': 'overflow-x'
  ),
  'overflow-y': (
    'items': ('hidden' : 'hidden'),
    'output': 'standard',
    'property': 'overflow-y'
  ),
  'p': (
    'items': $gorko-size-scale-important,
    'output': 'standard',
    'property': 'padding'
  ),
  'pb': (
    'items': $gorko-size-scale-important,
    'output': 'responsive',
    'property': 'padding-bottom'
  ),
  'pl': (
    'items': $gorko-size-scale-important,
    'output': 'responsive',
    'property': 'padding-left'
  ),
  'pr': (
    'items': $gorko-size-scale-important,
    'output': 'responsive',
    'property': 'padding-right'
  ),
  'pt': (
    'items': $gorko-size-scale-important,
    'output': 'responsive',
    'property': 'padding-top'
  ),
  'position': (
    'items': (
      'relative': 'relative', 
      'absolute': 'absolute',
      'fixed': 'fixed', 
      'static': 'static'
    ),
    'output': 'standard',
    'property': 'position'
  ),
  'right': (
    'items': (
      '0': 0, 
      'auto': 'auto',
    ),
    'output': 'standard',
    'property': 'right'
  ),
  'text': (
    'items': (
      'center': 'center',
      'left': 'left',
      'right': 'right'
    ),
    'output': 'standard',
    'property': 'text-align'
  ),
  'text-decoration': (
    'items': (
      'none': 'none',
      'underline': 'underline',
    ),
    'output': 'standard',
    'property': 'text-decoration'
  ),
  'white-space': (
    'items': (
      'nowrap' : 'nowrap'
    ),
    'output': 'standard',
    'property': 'white-space'
  ),
  'width': (
    'items': $gorko-widths,
    'output': 'standard',
    'property': 'width'
  ),
  'breakpoints': (
    'sm': '(min-width: 30em)',//480px
    'md-s': '(min-width: 40em)',//640px
    'md': '(min-width: 48em)',//768px
    'md-x': '(min-width: 64em)',//1024px
    'is-l': '(min-width: 70.5em)',//intersection-left; 1128px
    'cw': '(min-width: 75em)',//cw = content-width, 1200px
    'lg': '(min-width: 90em)',//1440px
    'mg': '(min-width: 100em)',//1600px
    'wd': '(min-width: 120em)',//1920px
    'wd-x': '(min-width: 138em)'//2208px
  ),
  'z': (
    'items': (
      '-1': -1, 
      '0': 0, 
      '1': 1, 
      '2': 2, 
      '10': 10, 
      '100': 100,
      '9999': 9999,
    ),
    'output': 'standard',
    'property': 'z-index'
  ),
  'u-frame': (
    'items': (
      '6' : 6,
      '8' : 8,
      '10' : 10,
      '12' : 12,
      '16' : 16,
    ),
    'output': 'responsive',
    'property': '--frame-n'
  )
);