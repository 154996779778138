html,
body {
  font-size: var(--step-0);
  line-height: 1.5;
}

body {
  @include apply-utility('font', 'primary');
  @include apply-utility('color', 'grey-900');
}

h1, h2, h3, h4, h5, h6, b, strong, em, i, button {
  font-weight: normal;
  font-style: normal;
}

// h1
// -----------------------------------------------------------------------------
h1 {
  @include apply-utility('font', 'primary-bold');
  font-size: get-size('4');
  line-height: get-size('5');
  
  @include media-query('sm') {
    font-size: get-size('5');
    line-height: get-size('6');
  }
}

// h2
// -----------------------------------------------------------------------------
h2 {
  @include apply-utility('font', 'secondary-bold');
  color: get-color('primary');
  font-size: get-size('2');
  line-height: 1.25;//get-size('2');
  margin-bottom: get-size('075');
}

// h3
// -----------------------------------------------------------------------------
h3 {
  @include apply-utility('font', 'secondary-bold');
  font-size: get-size('1');
  line-height: get-size('2');
  color: get-color('primary');
}

// h4
// -----------------------------------------------------------------------------
h4 {
  @include apply-utility('font', 'primary-bold');
}

// h5
// -----------------------------------------------------------------------------
//h5 {
//  @include sgl-heading(0, 0, 1, 0);
//}

// links
// -----------------------------------------------------------------------------
a {
  color: get-color('primary');
}

// others
// -----------------------------------------------------------------------------
ul,
ol {
  padding-left: 0;
  margin: 0;
}

ul {
  list-style: none;
}

ol {
  list-style: decimal;
}

.editorial, 
.is-editorial,
.has-list-editorial {  
  p + ul,
  p + ol {
    margin-top: var(--step-0);
  }
  
  ul {
    li + li {
      margin-top: var(--step--4);
    }
    
    &.u-stack-1 {
      li + li {
        margin-top: var(--step-1);
      }
    }
  }
  
  ol {
    li + li {
      margin-top: var(--step-0);
    }
  }
  
  ul,
  ol {
    li {
      ol {
        margin-left: var(--step-0);
        //margin-bottom: var(--step--2);
        //border-bottom: var(--step--2) solid palegoldenrod;
        
        & > li + li {
          //border-top-color: green;
          margin-left: 0;
        }
        
        li {
          ol {
            //margin-bottom: var(--step--2);
            //border-bottom: var(--step--2) solid blue;
            
            & > li + li {
              margin-top: var(--step--2);
              //border-top-color: yellow;
            }
          }
        }
      }
      
      ul {        
        li {
          &::before {
            background-color: get-color('grey-500');
          }
          
          li {
            &::before {
              background-color: transparent;//get-color('grey-700');
              border: 2px solid get-color('grey-500');
            }        
          }
        }
      }
    }
      
  }
    
  ul {
    li {
      padding-left: 1.25em;
      position: relative;

      &::before {
        background-color: get-color('secondary');
        border-radius: 100%;
        content: "";
        display: inline-block;
        flex-shrink: 0;
        height: .5em;
        left: 0.25em;
        position: absolute;
        top: .5em;
        width: .5em;        
      }
    }
  }
}

strong,
b {
  @include apply-utility('font', 'primary-bold');
}

em, 
i {
  @include apply-utility('font', 'primary-italic');

  strong,
  b {
    @include apply-utility('font', 'primary-bold-italic');
  }
}

small {
  font-size: var(--step--1);
  display: inline-block;
}

.is-editorial {
  * + p, 
  * + ul {
    margin-top: var(--step-0);
  }
  
  * + h2 {
    margin-top: var(--step-2);
  }
  
  * + h3 {
    margin-top: var(--step-3);
  }
}

pre {
  font-family: 'monospace',courier,sans-serif; 
  font-size: 1rem;
  line-height: 1;
  margin: .5rem 0 !important;
}