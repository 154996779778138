.c-fp-roof, 
.c-roof-front {
  position: absolute;
  width: 100%;
  z-index: 0;
  top: 0;
  //opacity: .5;

  .shape {
    fill: get-color('white');
  }
}