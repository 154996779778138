.u-switcher {
  display: flex;
  flex-wrap: wrap;
  gap: var(--step-0);
}

.u-switcher > * {
  flex-grow: 1;
  flex-basis: calc(( 28rem - 100%) * 999);
}

.u-switcher > :nth-last-child(n+ 3),
.u-switcher > :nth-last-child(n+ 3) ~ * {
  flex-basis: 100%;
}