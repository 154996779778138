// fieldset
// -----------------------------------------------------------------------------
.f-container {
  //display: inline-block;
  background-color: rgba(get-color('primary'), .1);
}

fieldset {
  border: 0 none;
  display: flex;
  flex-direction: column;
  padding: var(--step--1) var(--step-1); 
  
  & + fieldset {
    //@include u-border-color('primary');
    //@include u-border-opacity(10);    
    --border-opacity: .1;//get-config-value(10, 'opacity');
    border-color: rgba(get-color('primary'), var(--border-opacity));
    border-top-width: 2px;
    border-top-style: solid;
    //border-top: 1px dotted get-color('primary');
  }

  & > * + * {
    margin-top: var(--step-0);
  }
}

// legend
// -----------------------------------------------------------------------------
legend {
  color: get-color('primary');
  float: left;
  width: 100%;
}

// label
// -----------------------------------------------------------------------------
label {
  cursor: pointer;
  display: inline-block;
}

.textarea .f-label, 
.inputText .f-label, 
.select .f-label {
  margin-bottom: var(--step--4);
}

label:nth-child(n+2) {
  margin-right: var(--step--4);
}

.multiCheckbox label:nth-child(n+2) {
  display: block;
}

.f-label {
  color: get-color('grey-900');
  display: block;
  font-size: var(--step--1);
  //@include sgl-heading(-1, .75, 0, .25);
  //@include sgl-margins(0, .25);
}

// f-field
// -----------------------------------------------------------------------------
.f-field {
  border-radius: 4px;
  //@include sgl-paddings-custom(.25, .25);
  border: 0 none;//1px solid get-color('grey-500');
  padding: .5rem .75rem;
  width: 100%;
  
  &--select {
    //&:not(.interest) {
      width: auto;
      background-color: get-color('white');
    //}
    //@include media-query('sm') {
    //  width: auto;
    //}
  }

  &--textarea {
    height: 25em;
  }
  
  &:focus {
    border: 2px solid get-color('secondary');
  }
}

.multiCheckbox input,
.checkbox input,
.radio input {
  display: inline-block;
  margin-right: .25rem;
  width: 1rem;
  height: 1rem;
}

.f-text, 
.f-description, 
.description-Text {
  @include apply-utility('font', 'primary-italic');
  color: get-color('grey-700');
  font-size: var(--step--2);
}

.f-description {
  margin-top: var(--step--4);
}


.f-Group {
  & + .f-Group {
    border-top: 2px solid get-color('white');
    padding-top: var(--step--2);
  }
  
  &.submit {
    border-top: 0 none;
    text-align: right;
  }
}

.f-Group--inner > * + * {
  margin-left: var(--step--4);
}

input {
  &.button {
    @extend .c-button;
    background-color: get-color('ok');
    color: get-color('white');
  }
}

//.f-ThanxContainer {
  //display: none;
  //padding: 2em;
  //position: relative;
//}

/*.f-ThanxContainer-CodeButton {
  background-color: get-color('warn');
  border-radius: 1em;
  color: get-color('white');
  cursor: pointer;
  display: block;
  line-height: 1.6;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 1.6em;
}*/

.f-ThanxContainer-CodeButton {
  position: absolute;
  right: 0;
  
  svg {
    width: var(--step-2);
    height: var(--step-2);
  }
  
  .path {
    fill: get-color('warn');
  }
}

// utilities
// -----------------------------------------------------------------------------
.required {
  &:after {
    //-webkit-font-smoothing: antialiased;
    color: get-color('warn');
    //content: '\FE61';// Small Asterisk
    content: '\2605';// Black Star 
    font-size: .6em;
    font-variant: normal;
    font-weight: normal;
    //line-height: 1;
    margin-left: .125em;
    margin-right: .125em;
    position: relative;
    text-transform: none;
    top: -.6em;
  }
}

// Error
// -----------------------------------------------------------------------------
.f-ErrorWrapper {
  clear: right;
  color: get-color('white');
  position: relative;
  top: calc(var(--step--2) * -1);//-0.5em;

  &:before {
      @include triangle(var(--step--2), get-color('warn'), bottom);
      position: relative;
      left: var(--step-0);
  }

  .f-ErrorList {
      border-radius: 4px;
      background-color: get-color('warn');
      padding: var(--step--2) var(--step-0) var(--step--2) var(--step-1);

      &.f-ErrorList-single {
          list-style: outside none none;
          padding: 0.5em 1em;
      }
  }
}

.f-ErrorWrapper--form {
  clear: right;
  color: get-color('white');
  display: inline-block;
  position: relative;
  //top: calc(var(--step--2) * -1);//-0.5em;

  &:before {
      @include triangle(var(--step--2), get-color('warn'), top);
      position: absolute;
      left: var(--step-0);
      //bottom: calc(var(--step-5) * -1);
      top: 100%;
  }

  .f-ErrorList {
      border-radius: 4px;
      background-color: get-color('warn');
      padding: var(--step--2) var(--step-0) var(--step--2) var(--step-1);

      &.f-ErrorList-single {
          list-style: outside none none;
          padding: 0.5em 1em;
      }
  }
}
