.c-jobs-slider {  
  .slick-dots {    
    li {
      &.slick-active {
        button {
          background-color: get-color('white');
        }
      }      
    }
  }  
}

.c-jobs-slide__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: get-color('white');
  border-radius: get-size('025');
  margin-left: get-size('05');
  margin-right: get-size('05');
  padding: get-size('05') get-size('1');
  text-align: center;
  min-height: 9em;
  text-decoration: none;
}

.c-jobs-index-item {
  color: get-color('grey-900');
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: get-size('0');
  transition: .25s;
  
  &:hover {
    //background-color: rgba(get-color('secondary'), .1);
    box-shadow: 0 0 0.5em 0.1em get-color('grey-500');
    border-bottom-width: 0;
    border-top-width: 0;
  }
  
  h3 {
      flex: 1 0 55%;
  }

  p {
      flex: 0 1 15%;
  }
}

/* ---- isotope ---- */
.grid {
  background: #EEE;
  max-width: 1200px;
}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ui group */
.ui-group {
  display: inline-block;
  margin-right: 20px;
}

.ui-group h3 {
  display: inline-block;
  margin-right: 0.2em;
  font-size: 16px;
}

.ui-group .button-group {
  display: inline-block;
  margin-right: 20px;
}

/* color-shape */
.color-shape {
  width: 70px;
  height: 70px;
  margin: 5px;
  float: left;
}
 
.color-shape.round {
  border-radius: 35px;
}
 
.color-shape.big.round {
  border-radius: 75px;
}
 
.color-shape.red { background: red; }
.color-shape.blue { background: blue; }
.color-shape.yellow { background: yellow; }
 
.color-shape.wide, .color-shape.big { width: 150px; }
.color-shape.tall, .color-shape.big { height: 150px; }
