.c-fp-news-wrapper {
  @include media-query('md-s') {
    flex-direction: row;

    .is-theme-2 {
      border-left: 1px solid get-color('grey-500');
      //flex: 0 0 calc(34% - var(--step-0));
      margin-left: var(--step-0);
      padding-left: var(--step-0);
    }
  }
}

.c-fp-news-container__header {
  margin-bottom: var(--step--1);
  
  .is-theme-1 & {
    color: get-color('warn');
    border-bottom: 1px solid get-color('warn');
  }
  
  .is-theme-2 & {
    color: get-color('primary');
    border-bottom: 1px solid get-color('primary');
  }
}

.c-fp-news-container__body {
  display: flex;
  flex-direction: column;
  
  @include media-query('sm') {
    flex-direction: row;
  }
}

.c-fp-news {
  &.is-custom {
    & > * {
      flex: 1;
    }
  }
  
  @include media-query('md-x') {
    &.is-page {
      flex-direction: row;
    }
  }
}
